import { defineStore, storeToRefs } from 'pinia';
import {reactive} from 'vue';

import _ from 'lodash';
import {faker} from '@faker-js/faker';

import type * as dt from './sharedPojos';
import { computed } from '@vue/reactivity';
import { useLocalStorage, useStorage, type RemovableRef } from '@vueuse/core';



type SignInInfo = {
	//role: dt.Role;
	role: string;
	loggedUsr: dt.IUser;
};


interface IAppProps {
	appTitle: string;
	mainDrawerOpened: boolean;
	menuItems: Array<{title: string, value: string, link:string}>;
	signInStatus: SignInInfo;
}


export const useAppStore = defineStore("uiStore", () =>{
	const uiProps: IAppProps  = reactive({
		appTitle:  `${(process.env.VITE_APP_TITLE  ?? "Communics App -default-")} ${process.env.VITE_ENV}`,
		mainDrawerOpened: false,
		signInStatus: {} as SignInInfo,
		menuItems: [
			{
				title: 'Stories manager',
				value: 'foo',
				link: "/manager",
			},
			{
				title: 'Export stories',
				value: 'bar',
				link: "/",
			},
			{
				title: 'app settings',
				value: '...',
				link: "/",
			}]
	});
	const cachedResources = useLocalStorage("cachedResources", new Map());

	const isSignedIn = computed(()=>{
		return uiProps.signInStatus.loggedUsr !== null;
	});
	function switchMenuDrawer() {
		uiProps.mainDrawerOpened = !uiProps.mainDrawerOpened;
	}
	// function getImageURI(id?:string):string {
	// 	//console.log("getting image uri " + id);
	// 	return `${axios.defaults.baseURL}/mediaResource/media?mediaID=${id}`;
	// }
	
	// async function getImage(id?:string): Promise<string>{
	// 	const params = { "mediaID":id  };
	// 	const response = await axios.get(
	// 		`${axios.defaults.baseURL}/mediaResource/media`,
	// 		{params});

	// 	const image = ('data:image/jpeg;base64,' + btoa(
    //        new Uint8Array(response.data)
	// 	   	.reduce((data, byte) => data + String.fromCharCode(byte), '')
    //     ));
	// 	console.log(image.substring(0,140));
	// 	return image;
	// }
	
	function setSigned(usr:dt.IUser | null){
		if (usr === null) {uiProps.signInStatus = {} as SignInInfo;}
		else {
			uiProps.signInStatus = {
				loggedUsr: usr,
				role: "admin"
			};
		}
	}
	return {
		uiProps, 
		isSignedIn, 
		switchMenuDrawer, 
		setSigned,
//		getImageURI, 
//		getImage
	};
});

const anonymousName = faker.name.fullName();
export const anonymousUser = <dt.IUser>{
	id: faker.string.uuid(),
	oauthID: faker.datatype.string(),
	displayName: anonymousName,
	fullName: anonymousName,
	labelField: "anonymous user",
	colorField: faker.internet.color(),
	picture: faker.internet.avatar(),
	name: anonymousName,
	email: faker.internet.email(),
	roleID: "anonymous"
};




