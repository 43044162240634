<template>
	<div class="container bg-surface-variant">
		<div class="mx-auto item">
			<v-toolbar class="flex-grow-0 toolbar" flat>
					<v-btn class="toolbar-item" @click="managerSt.newActivity({})">new activity</v-btn>
					<v-btn class="toolbar-item" @click="managerSt.newStory({})">new story</v-btn>
					<v-btn class="toolbar-item" v-if="selectedStory !== undefined" @click="managerSt.deleteStory()">delete story</v-btn>
			</v-toolbar>
			<v-list class="overflow-y-auto" max-height="82vh">
				<v-list-subheader>Available sessions</v-list-subheader>

				<v-list-item v-for="story in managerSt.stories" :key="story.id" :value="story"
					active-class="highlighted" active-color="primary" @click="managerSt.selectStoryByID(story.id)"
					:active="isSelected(story)">
					<v-list-item-title v-text="story.title"></v-list-item-title>
					<v-list-item-subtitle>{{ formatDate(story.environment.lastEditAt) }}</v-list-item-subtitle>
				</v-list-item>
			</v-list>
		</div>

		<div v-if="selectedStory !== undefined" >
			<v-toolbar   class="flex-grow-0 toolbar" flat fixed overflow>
				<v-btn>embed story
					<v-dialog v-model="viewState.showEmbed" activator="parent" class="normalDialog">
						<v-card>
							<v-card-title>
								<span class="text-h5">Choose a story to embed</span>
							</v-card-title>
								<v-spacer></v-spacer>
							<v-list :items="embeddingStories"
								@click:select="embedStory"
								item-title="title"
								item-value="id"
								return-object></v-list>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn @click="viewState.showEmbed = false; ">close</v-btn>
							</v-card-actions>
							</v-card>
					</v-dialog>
				
				</v-btn>
				<v-btn @click="editStoryboard">edit storyboard</v-btn>
				<toolbar-progress :loading="openingStoryboard"></toolbar-progress>

				<!-- <toolbar-progress :loading="openingStoryboard" "></toolbar-progress>	 -->
			</v-toolbar>
			<!-- ############DETAIL OF STORY######-->
			<v-sheet class="overflow-y-auto"  max-height="80vh" @focusout="managerSt.updateStory()">
				<v-text-field v-model="selectedStory.title" :rules="nameRules" :counter="50" label="Title of the story"
					required></v-text-field>
				<p class="label">created by <b>{{ selectedStory?.environment?.creator?.fullName }}</b>
					[{{ selectedStory?.environment?.creator?.email }}]
					on {{ formatDate2(selectedStory?.environment?.createdAt) }};<br /> last changed at
					{{ formatDate2(selectedStory?.environment?.lastEditAt) }}

				</p>
				<v-select v-model="selectedStory.authors" 
					:items="managerSt.availableUsers" 
					label="Participants" 
					item-title="displayName"
					return-object
					@focusout="managerSt.updateParticipants()"
					multiple>
						
				</v-select>



					<!-- <div class="list-participant" v-for="(p, idx) in selectedStory?.participants" :key="p.id" :value="p">
						<b>{{ p.fullName }}</b> [{{ p.email }}]
					</div> -->
				<v-textarea v-model="selectedStory.settings.description" :rules="nameRules" label="Description" required>
				</v-textarea>
				<div class="label d-flex justify-start">
					<v-img v-for="(url) in getBackgrounds(4)" :key="url" :value="url" max-height="150" max-width="250"
						:src="url"></v-img>
				</div>

				<div class="label">
					<p>Options</p>
					<v-checkbox v-model="selectedStory.settings.hasFreeTexts" label="Free text?" required>
					</v-checkbox>
					<v-checkbox v-model="selectedStory.settings.hasPredefinedTexts" label="Predefined text?" required>
					</v-checkbox>
					<v-checkbox v-model="selectedStory.settings.hasMediator" label="Automatic mediator?"
						required></v-checkbox>
					<v-checkbox v-model="selectedStory.settings.hasChat" label="Chat?" required></v-checkbox>
				</div>
			</v-sheet>
		</div>
		<div v-else>
			SELECT A STORY or <v-btn @click="managerSt.newStory"> create </v-btn> a new empty story
		</div>
	</div>
</template>
<script setup lang="ts">
import { computed, nextTick, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useManagerStore } from '@/model/managerStore';
import ToolbarProgress from './ToolbarProgress.vue';
import type { IPanel, IStory, IUser } from '@/model/sharedPojos';
import * as _ from "lodash";
import dayjs from 'dayjs';
import { useServices } from '@/composable/useServices';





const managerSt = useManagerStore();
const route = useRouter();
const openingStoryboard = ref<boolean>(false);
const viewState = reactive({ 
	showAvailableUsersDialog: false,
	showEmbed: false,
	items: [
		{
			name: 'Item #1',
			id: 1,
		},
		{
			name: 'Item #2',
			id: 2,
		},
		{
			name: 'Item #3',
			id: 3,
		},
	],
});


const embeddingStories = computed(()=>{
	const retValue = _.differenceWith(
		managerSt.stories,
		managerSt.selectedStories,
		(a, b) =>a.id === b.id);console.log("on mounted");
	return retValue;
})

const selectedStory = computed(() => {
	return managerSt.selectedStories[0];
});


const nameRules = [
	(v: string) => !!v || 'Name is required',
	(v: string) => {
		console.log(`checking name ${v} ${v.length}`)
		return v.length > 3 || 'Name must be less than 3 characters'
	},
];

onMounted(async () => {
	if (managerSt.currentActivity.id === undefined) {
		await managerSt.setActivity();
	}
	await nextTick();
});

function isSelected(story:IStory): boolean {
	return (managerSt.selectedStories.filter(x => x.id === story.id).length > 0);
}

function back() {
	route.push({
		path: "/"
	});
}
function embedStory(e: any){
	//console.log(`event on select ${JSON.stringify(e)}`);
	const embedding = managerSt.stories.find(x => x.id === e.id);
	managerSt.selectedStories[0].storyboard = embedding?.storyboard.map(a => { return { ...a } }) ?? [] as IPanel[];
	managerSt.updateStory();

}
async function editStoryboard(){
	openingStoryboard.value = true;
	await managerSt.loadEditorResources();
	route.push({path:"/editStory"});
	openingStoryboard.value = false;
}

const isEmpty = computed(() => managerSt.selectedStories[0]?.storyboard.length < 0);
const incipt = computed(() => {
	const storyboard = selectedStory?.value?.storyboard;
	console.log("incipt of image")
	if (storyboard.length == 0) { return "" }
	console.log("found")
	return storyboard[0].background.mediaSourceUri;
});

function getBackgrounds(count: number): Array<string> {
	const storyboard = selectedStory?.value?.storyboard;
	if (storyboard.length == 0) { return new Array<string>(); }
	const api = useServices().api;
	return storyboard.slice(0, count).map(p => {
		console.log("url: "  + useServices().api.getImageURI(p.background.mediaSourceID));
		return useServices().api.getImageURI(p.background.mediaSourceID);
	});
};

function formatDate(d: Date): string {
	return dayjs(d).format('D-M-YYYY H:m');
}
function formatDate2(d: Date): string {
	return dayjs(d).format('dddd D, MMM YYYY  H:m');
}

</script>
<style lang="scss" scoped>
.container {
	display: grid;
	grid-template-columns: 27% auto;
	//grid-template-rows: auto;
	background-color: #1d1e1f;
	padding: 16px;
	height: 100%;
	column-gap: 0.5rem;
}

.toolbar-item{
	margin: 0.2rem;
}

.toolbar{
	margin-bottom: 1rem;
}
.item {
	border: 1px solid rgba(0, 0, 0, 0.8);
	width: 100%;
	justify-self: stretch;
}
.normalDialog{
	max-width: 40vw;
	max-height: 50vh;
 }
// .v-toolbar__extension {
// 	padding: 0px !important;
// }
// .full-height {
// 	margin:0px;
// 	height: 100%;
// }
// }
// @media (min-height: 301px) and (max-height: 600px){
// 	.full-height {
// 		height: vh;
// 	}
// }
// @media (max-height: 300px) {
// 	.full-height {
// 		height: 40vh;
// 	}
// }

.label {
	margin-top: 0rem;
	margin-left: 1rem;
	margin-bottom: 2rem;
	font-size: 0.9rem;
}

.panels-preview {
	display: flex;
	flex-direction: column;
}

.list-participant {
	font-size: 1rem;
}

.debug {
	background-color: #9aa665;
}
</style>