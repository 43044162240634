import { defineStore } from 'pinia';
import {reactive} from 'vue';
import { computed } from '@vue/reactivity';
import type  *  as dt  from './sharedPojos';
import { faker } from '@faker-js/faker';
import { useStoryEditorStore } from './storyEditorStore';




interface IChat {

	actions: Array<dt.IActionRule>;
}
export const useChatStore = defineStore("chatStore", ()=>{
	const data: IChat = reactive<IChat>({
		actions: new Array<dt.IActionRule>()
	} as IChat);

	const actionsRef = computed({
		get() {return data.actions ?? new Array<dt.IActionRule>();},
 		set (val:Array<dt.IActionRule>){data.actions = val;}
	});

	const description = computed(() =>{
		if (actionsRef.value.length ==  0) { return "no actions";}
		return actionsRef.value[0].description;

	});
	const resources = computed(() =>{
		if (actionsRef.value.length ==  0) { return "";}
		return actionsRef.value[0].resources;
	}); 

	const hasMessage = computed(() =>{
		return data.actions.length > 0;
	});

	// const nextAction = computed(() =>{
	// 	if (actionsRef.value.length == 1) { throw new Error("no next Action rule")}
	// 	return data.actions[1].description;
	// });

	async function doNext(): Promise<void>{
		console.log("close action and process next");
		data.actions.shift();
	}

	



	return {
		actionsRef,
		description,
		hasMessage,
		resources,
		//nextAction,
		doNext,
	}



});