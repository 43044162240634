import { defineStore, storeToRefs } from 'pinia';
import {computed, reactive} from 'vue';
import * as _ from "lodash";
import {faker} from '@faker-js/faker';
import axios from 'axios';

import type { IUser, IStory, IPanel, IMedia } from './sharedPojos';
import type {IActivity } from './activity';
import { useCommunicsStore } from './communicsStore';



const fakedStories = [...Array(20).keys()].map(x => 
	<IStory> {
		id: faker.string.uuid(),
		title: faker.commerce.productName(),
		environment: {
			createdAt: faker.date.past(10),
			lastEditAt: faker.date.past(4),
			startedAt: faker.date.recent(),
			
		},
		settings: {
			description: faker.lorem.lines(),
			hasFreeTexts: faker.datatype.boolean(),
			hasChat: false,
			hasMediator: faker.datatype.boolean(),
			hasPredefinedTexts: faker.datatype.boolean(),
			isTesting: faker.datatype.boolean(),
			resourcesNamespace: "sbmt-res"
		},
		authors: new Array<IUser>(),
		participants:[...Array(faker.datatype.number(5)).keys()].map(x =>{
			const u = {id: faker.string.uuid(),
				fullName: faker.name.fullName(),
				email: faker.internet.email()} as IUser
			return u;
		}),
		storyboard:  [...Array(faker.datatype.number(10)).keys()].map(x =>{
			return {
				id: faker.string.uuid(),
				background: {
					id: faker.string.uuid(),
					mediaSourceUri: faker.image.url({width: 250, height:250}),
				} as IMedia
			} as IPanel;
		}),
	});


export const useManagerStore = defineStore("managerStore", () =>{
	const data = reactive({
		currentActivity: {} as IActivity,
		stories: fakedStories,
		selectedStories: new Array<IStory>(),
	});

	const stories = computed(() => {
		return data.stories;
	});

	const availableUsers = computed(()=>{return data.currentActivity.availablePeople});
	const currentActivity = computed(()=>{return data.currentActivity});

	const selectedStories = computed(()=>{
		return data.selectedStories;
	})

	function selectStoryByID(storyID: string): void{
		const st:IStory = data.stories.find((el, idx) =>el.id === storyID) ?? {} as IStory;
		selectStory(st);
	}


	function selectStory(story:IStory):void {
		data.selectedStories.length = 0;
		data.selectedStories.push(story);
	};

	async function setActivity(activityID?: string):Promise<void>{
		let activity = {} as IActivity;
		if (activityID !== undefined) {
			;
		}
		else {
			const r = await axios.get("/activities/first");
		 	activity = JSON.parse(r.data) as IActivity;
		}
		console.log(`checking ${activity.id}`);
		const params = {activityID: activity.id};
		const response = await axios.get("/activities/activity/stories", {params});
		updateActivity(activity);
		updateStories(JSON.parse(response.data))
	}

	function updateActivity(p: IActivity){
		data.currentActivity = p;
		data.stories = p.stories;
		data.selectedStories.length = 0;
	}

	async function newActivity(params:any): Promise<void> {
		await axios.post("/activities/activity/new", params);
	}

	async function newStory(params:any):Promise<void>{
		const story = fakedStories[faker.datatype.number(10)];
		story.title = faker.datatype.string(10);
		story.storyboard.length = 0;
		params = {
			story: story,
			activityID: data.currentActivity.id,
		};
		console.log(params);
		const storyID = JSON.parse((await axios.post("/activities/activity/addStory", params )).data).id;
		console.log(`new id: ${storyID};`);
		const sts = JSON.parse((await axios.get("/activities/activity/stories", params)).data) as IStory[];
		updateStories(sts);
		selectStoryByID(storyID);
	}
	async function deleteStory():Promise<void>{
		console.log("delete story");
		const payload = {
			storyID: data.selectedStories[0].id,
			fromActivityID: data.currentActivity.id
		};
		const idx = data.stories.findIndex(f => f.id === data.selectedStories[0].id);
		const response = await axios.delete("/activities/activity/deleteStory", {data: payload} );
		const sts = JSON.parse((await axios.get("/activities/activity/stories", {})).data) as IStory[];
		updateStories(sts);
	}

	function updateStories(stories: IStory[]){
		data.stories = stories;
		data.selectedStories.length = 0;
	}
	async function updateStory():Promise<void>{
		const params = {
			activityID: data.currentActivity.id,
			updatingStory: data.selectedStories[0]
		}
		const response = await axios.post("/activities/activity/updateStory", params);
		const story = JSON.parse(response.data) as IStory;
		data.selectedStories[0] = story;
	}

	async function updateParticipants(){
		const activity = data.currentActivity;
		//console.log(`before lenght ${activity.availablePeople.length}`)
		const story = data.selectedStories[0];
		// const participantDiff = _.differenceWith(
		// 	activity.availablePeople,
		// 	story.authors,
		// 	(a, b) => a.email === b.email);
		// activity.availablePeople = participantDiff;
		await updateStory();
	}

	async function loadEditorResources(){
		const story = data.selectedStories[0];
		if (story !== undefined) {
			const store = useCommunicsStore();
			await store.updateCurrentStoryAsync(story);
		}
	}
	
	return {
		stories, 
		availableUsers,
		currentActivity,
		selectedStories,
		setActivity,
		newActivity,
		newStory, 
		updateStory,
		selectStoryByID,
		deleteStory,
		updateParticipants,
		loadEditorResources,
	}
});