import { useLocalStorage } from '@vueuse/core';
import axios from 'axios';

//import {authS}
export default class  ApiService {

	
	constructor(private readonly accessToken: string) {
		
	}


	 getDefaultHeader() {
		return { Authorization: `Bearer ${this.accessToken}` }
  	}

	getImageURI(id?:string):string {
		//console.log("getting image uri " + id);
		return `${axios.defaults.baseURL}/mediaResource/media?mediaID=${id}`;
	}
	async getImage(id?:string): Promise<string>{
		const params = { "mediaID":id  };
		const response = await axios.get(
			`${axios.defaults.baseURL}/mediaResource/media`,
			{params});

		const image = ('data:image/jpeg;base64,' + btoa(
           new Uint8Array(response.data)
		   	.reduce((data, byte) => data + String.fromCharCode(byte), '')
        ));
		console.log(image.substring(0,140));
		return image;
	}

	async getImage2(id?:string, config?: {preload?:boolean, useCache?:true}): Promise<string>{
		if (id === undefined) {
			throw new Error("undefined image id specified");
		}
		if (config?.useCache) { //use cache
			const url = localStorage.getItem(id) ??  ""; 
			if (url !== undefined ){return url;} //try to load from localstorage
		}
		const response = await axios.get(
			`${axios.defaults.baseURL}/mediaResource/media?mediaID=${id}`, 
			{responseType: "arraybuffer"}
		);
		const blob = new Blob([response.data], {type: 'image/jpg'});
		const url = URL.createObjectURL(blob);
		if (config?.preload) {
			//window.localStorage.setItem(id, url);
			localStorage.setItem(id, url);
		}
		return url;
	}
	



}
