<template>
	<div></div>
</template>
<script setup lang="ts" >
import { onMounted } from 'vue'
import { useRouter } from 'vue-router';
import { useAuth } from '@/model/authStore'
import {useServices} from '@/composable/useServices'
import type { User } from 'oidc-client-ts';

const authStore = useAuth();
const router = useRouter();
const services = useServices();
onMounted(async () =>{
	try {
		console.log("loading auth call back");
		await services.auth.signInCallback();
		const user = (await services.auth.getUser()) as User;
		if (user !== undefined) {
			console.log("invoking credentials from authcallback");
			authStore.setupUserCredentials(user);
			//services.api.setupCredentials(authStore.accesToken);
		}
		router.replace('/');
	} catch (e) {
		console.error(`error in callback: ${e}`);
	}
});
</script>