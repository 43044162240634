<template>
	<!-- <v-progress-linear :active="loading" color="green" indeterminate class="ma-0" height="8"
		style="top: -2px;"></v-progress-linear> -->
		<!-- <v-progress-linear indeterminate color="yellow-darken-2"></v-progress-linear> -->
		<v-progress-circular :style="{display:d}" color="light-green accent-2"
			indeterminate :size="30" :width="3"></v-progress-circular>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
	loading: boolean,
}>();

const d = computed(() =>{
	console.log(`call d ${props.loading}`);
	if (props.loading) {return 'visible';}
	return 'none';

})



</script>
<style lang="scss" scoped>
.v-progress-linear {
	-moz-transform: scale(1, -1);
	-webkit-transform: scale(1, -1);
	-o-transform: scale(1, -1);
	-ms-transform: scale(1, -1);
	transform: scale(1, -1);
}
</style>