<template>
	<div>reloading </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRouter } from 'vue-router';
import type { User } from 'oidc-client-ts'
import { useServices } from '@/composable/useServices';
import { useAuth } from '@/model/authStore';

const services = useServices();
const authStore = useAuth()
const router = useRouter();
onMounted(async () => {
	try {
		await services.auth.signInCallback();
		const user = (await services.auth.getUser()) as User;
		console.log("on mounted in silent renew page");
		if (user !== undefined) {
			
			authStore.setupUserCredentials(user);
			//services.api.setupCredentials(authStore.accesToken);
		}
		router.replace('/');

	} catch (e) {
		console.error(e)
	}
});
</script>