import { reactive } from '@vue/reactivity';
import { computed } from '@vue/reactivity';
import { defineStore } from 'pinia';
import axios from 'axios';
import _ from 'lodash';

import type * as dt from './sharedPojos';
import { useStoryEditorStore } from './storyEditorStore';



interface PanelStore {
	currentPanel:dt.IPanel;
	currentEditingElement:dt.IContent;
	editingObjectIdx: number  | null;
}

export const usePanelStore  = defineStore("panelStore", () => {
	const data = reactive<PanelStore>({
		currentPanel: {} as dt.IPanel,
		currentEditingElement: {} as dt.IContent,
		editingObjectIdx:  null,
	});

	const currentPanel = computed(() => data.currentPanel);
	const currentEditingElement = computed(() => data.currentEditingElement);

	function changePanelSelection(p:dt.IPanel): void {
		if (p === data.currentPanel) {return;}
		data.currentPanel = p;
		clearObjSelection();
	}

	async function updateStory(): Promise<void> {
		const {storyRef} = useStoryEditorStore();
		const params = { updatingStory:storyRef }
		await axios.post("/editor/story/update", params);
	}

	async function deleteFloatingObjASync(): Promise<void>{
		data.currentPanel?.sceneObjects?.forEach((element, i) => {
			if (element.uuid === data.currentEditingElement?.uuid) {
				data.currentPanel?.sceneObjects?.splice(i, 1);
				clearObjSelection();
			}
		});
		data.currentPanel?.characters?.forEach((element, i) => {
			if (element.uuid === data.currentEditingElement?.uuid) {
				data.currentPanel?.characters?.splice(i, 1);
				clearObjSelection();
			}
		});
		data.currentPanel?.balloons?.forEach((element, i) => {
			if (element.uuid === data.currentEditingElement?.uuid) {
				data.currentPanel?.balloons?.splice(i, 1);
				clearObjSelection();
			}
		});
		await updateStory();
	}
	async function flipHorizontalASync():Promise<void>{
		console.log("flip hor");
		await updateGeometry({flipHor: true});
	}
	async function flipVerticalASync():Promise<void>{
		console.log("flip veryt");
		await updateGeometry({flipVer: true});
	}
	function setEditingContent(obj:dt.IContent):void {
		data.currentEditingElement = obj;
	}
	function clearObjSelection() {
		data.currentEditingElement = {} as dt.IContent;
	}
	async function changeTextStyle(balloon:dt.IBalloon, newStyle?: string): Promise<void>{
		const textStyle = newStyle ?? balloon.style;
		if (textStyle === "speech") {
			balloon.style = "thought";
		}
		else if (textStyle === "thought"){
			balloon.style = "caption";
		}
		else {balloon.style = "speech";}
		await updateStory();
	}
	async function changeTailOrientationAsync(orientation: string, balloon:dt.IBalloon): Promise<void>{
		balloon.tailOrientation = orientation;
		await updateStory();
	}
	async function updateText(text:string):Promise<void> {
		if (data.currentEditingElement === undefined) {
			console.error("updating text on undefined ballon");
			return;
		}
		(data.currentEditingElement as dt.IBalloon).text = text;
		console.log(`updating text with ${text}`);
		await updateStory();
	}

	async function updateGeometry(
		geomData:{
			x?: number, 
			y?:number, 
			rot?: number, 
			zoomX?: number, 
			zoomY?:number, 
			flipHor?:boolean, 
			flipVer?:boolean}):Promise<void>{
		if (data.currentEditingElement === undefined) {return}
		console.log(`updating geom ${JSON.stringify(geomData)} for ${data.currentEditingElement.uuid}`);
		data.currentEditingElement.xPosition = geomData.x ?? data.currentEditingElement.xPosition;
		data.currentEditingElement.yPosition = geomData.y ?? data.currentEditingElement.xPosition;
		data.currentEditingElement.rotationAngle = geomData.rot ?? data.currentEditingElement.rotationAngle;
		if (geomData.zoomX !== undefined){
			data.currentEditingElement.xZoomFactor = geomData.zoomX;
		}
		if (geomData.zoomY !== undefined){
			data.currentEditingElement.yZoomFactor  = geomData.zoomY ;
		}
		if (geomData.flipHor !== undefined) {	
			const val  = data.currentEditingElement.horzFlip ?? 1.0; //real or default value
			data.currentEditingElement.horzFlip = geomData.flipHor?  val * -1.0: 1.0; 
		}
		if (geomData.flipVer !== undefined) {	
			const val = data.currentEditingElement.vertFlip ?? 1.0; 
			data.currentEditingElement.vertFlip = geomData.flipVer ?val * -1.0: 1.0; 
		}
		await updateStory();
	}

	async function changeCharacterProperty(serviceRequest:string, originPath: string):Promise<void>{
		if (data.currentEditingElement === undefined) {return}
		console.log("origin path" + originPath);
		const mediaElement = data.currentEditingElement as dt.IMediaElement;
		const params = { path:mediaElement.media.resourcePath };
		const mediaSource = JSON.parse(
			(await axios.get(serviceRequest, {params}))
			.data) as dt.IMediaSource;
		mediaElement.media.mediaSourceUri = mediaSource.resourceUri;
		mediaElement.media.mediaSourceID = mediaSource.id;
		mediaElement.media.resourcePath =  mediaSource.path;
		await updateStory();
	}


	async function nextFaceExpression(originPath:string ): Promise<void>{
		await changeCharacterProperty("/mediaResource/nextFaceExpression", originPath);
	}

	async function changeStyle(originPath: string): Promise<void>{
		await changeCharacterProperty("/mediaResource/nextStyle", originPath);
	}

	return {
		data,
		changePanelSelection,
		currentEditingElement,
		currentPanel,
		setEditingContent,
		updateGeometry,
		deleteFloatingObjASync,
		flipHorizontalASync,
		flipVerticalASync,
		clearObjSelection,
		changeTextStyle,
		changeTailOrientationAsync,
		nextFaceExpression,
		changeStyle,
		updateText,
	}
});