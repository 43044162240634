
<template>
	<div class="panel-container" @keyup="clearAbleSelection">
		<div class="panel-content">
			<able-component class="characters" v-for="(c, idx) in props.panel.characters" :media-element="c" :id="c.uuid" :key="idx"
				@blur.stop="($ev: FocusEvent) => clearAbleSelection($ev)"
				@contextmenu.stop="async (ev: MouseEvent) =>await handleContextMenuOnFloating(ev, c, idx)">
			</able-component>
			<able-component class="objs" v-for="(obj, idx) in props.panel.sceneObjects" :media-element="obj" :id="obj.uuid" :key="idx"
					@contextmenu.stop="async (ev: MouseEvent) => await handleContextMenuOnFloating(ev, obj, idx)">
				</able-component>
			<balloon-component class="balloons" v-for="(c, idx) in props.panel.balloons" :content-prop="c" :id="c.uuid" :key="idx"
				@contextmenu.stop="async (ev: MouseEvent) =>await handleContextMenuOnBaloon(ev, c, idx)">
			</balloon-component>
					<!-- <button class="button" @click="changeColor">Click me</button> -->
		</div>


		<v-img class="background" 
			:src="rawBackground"></v-img>
		<v-menu class="menu" v-model="menuModel.showMenu"
			:style="{top: menuModel.yPos + 'px' , left: menuModel.xPos + 'px'}">
			<v-list>
				<v-list-item v-for="(i, index) in menuModel.items" :key="index" @click="i.action()">
					<v-list-item-title>{{ i.title }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

		
	</div>
</template>

<script setup lang="ts">
import { useServices } from '@/composable/useServices';
import AbleComponent from './AbleComponent.vue';
import BalloonComponent from "./BalloonComponent.vue";

import { nextTick, onBeforeMount, onMounted, reactive, ref } from "vue";
import type { IContent, IPanel, IStory, IBalloon } from "@/model/sharedPojos";

import { usePanelStore } from '@/model/panelStore';




interface IPanelEditorProps {
	story?: IStory;
	panel: IPanel;
};

interface IContextMenuItem {
	title: string;
	value?: string;
	action: () => void;
}
interface IMenuModel {
	xPos: number;
	yPos: number;
	showMenu: boolean;
	items: Array<IContextMenuItem>;
}
const menuModel = reactive<IMenuModel>({
	xPos: 100,
	yPos: 150,
	showMenu: false,
	items: [],
	
});
const services = useServices();
const panelStore =  usePanelStore();
const props = defineProps<IPanelEditorProps>();
const rawBackground = ref<string>("");
// const emits = defineEmits<{
// 	(e: "selectPanel", panel: IPanel): void
// }>();
// //const propProxy = reactive({color: "blue", transform: "rotate(0deg) scale(1,1)"});

// function handleSelection(ev: Event) {
// 	console.log(`selected into panel`);
// 	emits("selectPanel", props.panel);
// }

// function changeColor(ev:Event) {
// 	propProxy.color = "red";
// 	propProxy.transform = "translate(10px, 10px) rotate(30deg) scale(1, 1)";
// }

onBeforeMount(async ()=>{
	console.log(`mounted model with id ${props.panel.uuid}`);
	//await panelStore.updateAsync(props.panel);
	
});
onMounted(async () =>{
		rawBackground.value = await services.api.getImage2(props.panel.background.mediaSourceID, {useCache:true});
});

function clearAbleSelection(ev:any){
	console.log("lost focus on able");
	//panelStore.clearObjSelection();
}
async function handleContextMenuOnFloating(e: MouseEvent, obj: IContent, idx: number): Promise<void> {
	e.preventDefault();
	panelStore.changePanelSelection(props.panel);
	panelStore.setEditingContent(obj);
	menuModel.items =[{
			title: 'Delete object',
			action: async () => await panelStore.deleteFloatingObjASync()
		}, {
			title: 'Flip horizontal',
			action: async () => {
				await panelStore.flipHorizontalASync();
				await nextTick();
			}
		},{
			title: 'Flip vertically',
			action: async () =>{
				await panelStore.flipVerticalASync();
				await nextTick();
			}
		}];
	menuModel.showMenu = false;
	menuModel.xPos = e.clientX;
	menuModel.yPos = e.clientY;
	await nextTick();
	menuModel.showMenu = true;
}


async function handleContextMenuOnBaloon(e: MouseEvent, balloon: IBalloon, idx: number): Promise<void> {
	e.preventDefault();
	panelStore.changePanelSelection(props.panel);
	panelStore.setEditingContent(balloon);
	menuModel.items = [{
		title: 'Delete text',
		action: async () => await panelStore.deleteFloatingObjASync()
	}];
	if (balloon.style !== "speech") {
		menuModel.items.push({
			title: 'Speech style',
			action: async () => await panelStore.changeTextStyle(balloon, "speech")
		});
	}
	if (balloon.style !== "caption") {
		menuModel.items.push({
			title: 'Caption style',
			action: async () => await panelStore.changeTextStyle(balloon, "caption")
		});
	}
	if (balloon.style !== "thought") {
		menuModel.items.push({
			title: 'Thought style',
			action: async () => await panelStore.changeTextStyle(balloon, "thought")
		});
	}
	menuModel.showMenu = false;
	menuModel.xPos = e.clientX;
	menuModel.yPos = e.clientY;
	await nextTick();
	menuModel.showMenu = true;
}


</script>

<style lang="scss" scoped>

$ballons-z-index: 14;
$objs-z-index: 12;
$panel-content-z-index:10;
$characters-z-index: 13;
$image-bg-z-index: 1;




.panel-container {
	display: inline-flex;
	position: relative;
	//align-items: stretch;
	// height: 200px;
	// width: 300px;
	max-width: $panel-max-width;
	max-height: $panel-max-height;
	// margin-top:5rem;
	// margin-left:2px 
}
.menu{
	position: absolute;
}
.panel-content {
	position: absolute;
   	top: 0px;
	bottom: 0px;
  	left: 0px;
  	right: 0px;
   //display:inline-block;
	z-index: $panel-content-z-index;
	width: 100%;
	height: 100%;
	// border-color: #b562ec;
	// border-style: dotted;
	overflow: clip;

}
.objs {
	/*background-color: rgba(80, 80, 80, 0.5);*/
	z-index: $ballons-z-index;
}
.characters {
	/*background-color: rgba(80, 80, 80, 0.5);*/
	z-index: $characters-z-index;
}
.balloons {
	z-index: $ballons-z-index;

}
.background {
	//background-color: #FFaa33;
	max-width: 800px;
	max-height: 550px;
	min-width: 800px;
	min-height: 570px !important;
	z-index: $image-bg-z-index;
	display: block;
}


</style>