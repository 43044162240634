import { defineStore} from 'pinia'
import {computed} from 'vue'
import {useCommunicsStore} from '@/model/communicsStore'

import {ref} from 'vue'
import axios from 'axios'
import _ from 'lodash'
import jwt_decode, { type JwtPayload } from "jwt-decode";

import type {User} from 'oidc-client-ts'
import type * as dt from './sharedPojos';

export const useAuth = defineStore("auth", () =>{
	const authUser = ref<User | null>(null);
	const loggedUsr = ref<dt.IUser |null>();
	const accessToken = computed(() => authUser.value?.access_token ?? '');
	const isLoggedIn = computed(() => !!loggedUsr.value);
	const userPicture = computed(()=> authUser.value?.profile?.picture);
	const communicsStore = useCommunicsStore();

	
	const getUserInfo = async (u: User):Promise<dt.IUser> => {
		if (u === undefined) {throw new Error("undefined user");}
		const decoded:any = jwt_decode<JwtPayload>(u.access_token);
		console.log(`access Token decoded ${JSON.stringify(decoded)}`);
		const params = { 
			"email": decoded?.email,
			"displayName": decoded?.given_name ?? "undefined",
			"oauthID": decoded?.sub } as dt.IUser;
		let user = {} as dt.IUser;
		try {
			console.log(`params of usr info ${JSON.stringify(params)}`);
			 axios.defaults.headers.post["Content-Type"] = "text/plain";
			 const s = (await axios.post(
			 	'/users/retrieveUser',
				params
			)).data;
			const users = (JSON.parse(s) as Array<any>);
			if (users && users.at(0)) {user = users.at(0) as dt.IUser;}
		}
		catch (error: unknown) {
			console.log(error);
		}
		return user ;
	};


	
	
	const setupUserCredentials = async (user: User): Promise<void> =>{
		authUser.value = user;
		console.log("request user");
		const u:dt.IUser = await getUserInfo(user);
		if (u === undefined) {
			console.log("no user found in authenticated users")
			return;
		}
		loggedUsr.value = u;
		window.localStorage.setItem("loggedUser", JSON.stringify(u));
		await communicsStore.fetchUserStories(u);
	};
	async function setupSharedUsr(email: string): Promise<dt.IUser>{
		await clearUserSession();
		if (email !== undefined) {
			const params = { email: email }
			 const s = (await axios.post(
			 	'/users/retrieveUser',
				params
			)).data;
			const users = (JSON.parse(s) as Array<any>);
			if (users && users.at(0)) {
				const user  = users.at(0) as dt.IUser;
				loggedUsr.value = user;
				return user;
			}
		}
		throw new Error("no user found");
	}
	
    const clearUserSession = async (): Promise<void> =>{
		await communicsStore.clearUser();
		window.localStorage.removeItem("loggedUser");
		//window.sessionStorage.clear();
		authUser.value = null;
		loggedUsr.value = null;
	};
	async function tryToUseLocals(): Promise<void> {
		const data =  window.localStorage.getItem("loggedUser");
		if (data === null) {return;}
		//const result = safeJsonParse(isIUser)(window.localStorage.getItem("loggedUser") ?? "");
		const user = JSON.parse(data as string) as dt.IUser;
		if (user.id !== undefined) {
			loggedUsr.value = user;
			await communicsStore.fetchUserStories(user);
		}

	}


	return {
		setupUserCredentials,
		setupSharedUsr,
		clearUserSession,
		accessToken,
		isLoggedIn,
		loggedUsr,
		userPicture,
		tryToUseLocals,
	}

});


// if (import.meta.hot) {
//   import.meta.hot.accept(acceptHMRUpdate(useAuth, import.meta.hot))
// }