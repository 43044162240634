import { createApp, reactive } from 'vue';
//import 'vuetify/styles' // Global CSS has to be imported
//import './styles/_variables.scss';

import * as components from "vuetify/components";
import colorsLocal from './utils';
import { createVuetify, type ThemeDefinition } from 'vuetify';
import * as directives from 'vuetify/directives';
import '@mdi/font/scss/materialdesignicons.scss'; // Ensure you are using css-loader
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';

import './directives/hScroll';
import hWheelScroll from './directives/hScroll';
import visibility from './directives/visibility';
import { createPinia} from 'pinia';
import axios from 'axios'; 
import App from './App.vue';
import { useAppStore } from '@/model/appStore';
import { useCommunicsStore } from './model/communicsStore';
import {router} from './services/router';



 
console.log(`############# ${process.env.VITE_ENV}`)
axios.defaults.baseURL = (process.env.VITE_BACKEND_ENDPOINT ?? "http://localhost:3002/")
    .concat("api-v2");
console.log(`#### base address:  ${axios.defaults.baseURL}`);

const communicsLightTheme: ThemeDefinition = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        surface: '#FFFFFF',
        primary: colorsLocal.red.lighten1,
        secondary: colorsLocal.green.lighten1,
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    }
}



const app = createApp(App);
app.config.errorHandler = (err) => {
    console.log(err)
}
app.config.performance = true;
app.directive('hWheelScroll', hWheelScroll);
app.directive("visibility", visibility);
const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases: {
			...aliases,

		},
        sets: { mdi },
    },
    theme: {
        defaultTheme: 'communicsLightTheme',
        themes: {
            communicsLightTheme
        }
    },
});
app.use(vuetify);
// app.use(vue3GoogleLogin, {
//     clientId:
//         '609692984244-3qdhsp0am5iieanm86rpdrggltjcuskp.apps.googleusercontent.com',
// });
app.use(createPinia());
app.provide("storeApp", useAppStore());
app.provide("appState", useCommunicsStore());
app.use(router);
app.mount('#app');



