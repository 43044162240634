<template>
	<v-app @contextmenu.prevent>
		<v-app-bar color="primary" dense  style="height:64px">
			<v-app-bar-nav-icon v-if="appStore.isSignedIn" @click.stop="appStore.switchMenuDrawer()"> </v-app-bar-nav-icon>
			<v-toolbar-title>
				<div class="d-inline-flex flex-row ">
					
					<!-- <p>{{ appStore.uiProps.appTitle }} </p> -->
					<input class="title" type="text" v-if="storyEditorStore.storyRef !== undefined"
						v-model="storyEditorStore.title" />

				</div>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon  v-if="authStore.isLoggedIn" @click="handleLogout">
	        	<v-icon :icon="mdiExport"></v-icon>
	      	</v-btn>
			<!-- <SignIn /> -->
		</v-app-bar>
		<v-navigation-drawer v-if="appStore.isSignedIn" v-model="appStore.uiProps.mainDrawerOpened" bottom temporary>
			<v-list>
				<v-list-item v-for="(item, i)  in appStore.uiProps.menuItems" :key="i" :to="item.link" link>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item>

			</v-list>
		</v-navigation-drawer>

		<v-main class="d-flex align-top mt-16 pa-4" style="min-height: 300px;">
			<router-view></router-view>
		</v-main>
	</v-app>
</template>


<script setup lang="ts">
// import HelloWorld from "./components/HelloWorld.vue";
// import TheWelcome from "./components/TheWelcome.vue";
import { mdiLogin, mdiExport, mdiAccountMultipleOutline } from '@mdi/js';
import { onMounted, ref } from "vue";

// import SignIn from "./components/SignIn.vue";
import { storeToRefs } from 'pinia';
import { useAppStore } from './model/appStore';
import { useAuth } from './model/authStore';
import { useStoryEditorStore } from './model/storyEditorStore';
import { useServices } from './composable/useServices';


const appStore = useAppStore();
const authStore = useAuth();
//const appState: IappState = inject('appState') as IappState
const storyEditorStore = useStoryEditorStore();


onMounted(async () => {
	//await login();
	console.log("mounted App");
	window.addEventListener("contextmenu", (event) => {
		event.preventDefault();
	});
	try {
		await authStore.tryToUseLocals();

	}catch (error){console.error(error);}

})

async function handleLogout(ev: Event):Promise<void>{
	await useServices().auth.signOut();
	await authStore.clearUserSession();
}
</script>


<style  scoped lang="scss">
@import './assets/base.css';


#app {
	/* max-width: 1280px; */
	margin: 0 auto;
	/* padding: 2rem; */
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

.login-req {
	padding-top: 3rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	row-gap: 10px;
	height: 30rem;
	max-height: 600rem;
	max-width: 800rem;
	/* background-color: green; */
}

.border {
	border-width: 1px;
	border-color: black;
	background-color: aqua;
}

.h1 {
	padding-top: 3rem;
	padding-bottom: 5rem;
	font-size: xx-large;


}

.title {
	margin-left: 0.4rem;
	color: $text-base-color-3;


}

.descr {
	flex: 1;
	margin-left: 1rem;
}

/* 
header {
	line-height: 1.5;
}

.logo {
	display: block;
	margin: 0 auto 2rem;
}

a,
.green {
	text-decoration: none;
	color: hsla(160, 100%, 37%, 1);
	transition: 0.4s;
}

@media (hover: hover) {
	a:hover {
		background-color: hsla(160, 100%, 37%, 0.2);
	}
}

@media (min-width: 1024px) {
	body {
		display: flex;
		place-items: center;
	}

	#app {
		display: grid;
		grid-template-columns: 1fr 1fr;
		padding: 0 2rem;
	}

	header {
		display: flex;
		place-items: center;
		padding-right: calc(var(--section-gap) / 2);
	}

	header .wrapper {
		display: flex;
		place-items: flex-start;
		flex-wrap: wrap;
	}


	.logo {
		margin: 0 2rem 0 0;
	}
}  */
</style>
