<template>
	<v-container fluid>
		<v-layout row d-flex >
			<div pa-1 class="storyboard" v-hWheelScroll ref="storyboardElement">
				<div v-for="(p, idx) in storyboardRef" v-bind:key="p.uuid" :draggable="canModify" 
					@dragenter.prevent
					@dragover.prevent 
					@dragstart="handleDragStart($event, idx)" 
					@drag="handleDrag"
					@drop.prevent="handleDrop($event, idx)" 
					@dragOver="handleDragOver"
					:class="{ selectedPanel: selectedPanelIdx === idx }"
					:id="p.uuid"
					ref="panels"
					style="display: inline-block; padding:3px"	

					@click="(ev) => handlePanelSelection(ev, idx)">
					<input 
						:disabled="!canModify"
						class="caption" :value="p.title" @blur="(ev: Event) => updateTitle(ev, p)"/>
				
					<panel-component :panel="p" 
						:disabled="!canModify"
						@contextmenu.stop="(ev: MouseEvent) => handleContextMenu(ev, idx)"></panel-component> 
						 <!-- <v-img class="panel" :src="getStream(idx)"></v-img> -->
				</div>
				<v-menu :style="{ top: menuModel.yPos + 'px', left: menuModel.xPos + 'px' }" class="overlay" 
					v-if="canModify"
					v-model="menuModel.showMenu">
					<v-list>
						<v-list-item v-for="(i, index) in menuModel.items" :key="index" @click="i.action()">
							<v-list-item-title>{{ i.title }}</v-list-item-title>
							
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
			 <!-- <v-flex> -->
			<ActionRulesComponent  class="rules pa-1" v-if="hasActionRules" >
			</ActionRulesComponent>

			
		</v-layout>
		<v-layout row class="d-flex mt-5">
			<LibrariesComponent class="library flex-1-1-100  " v-if="canModify" />
		</v-layout>
		<v-layout row>
			<v-toolbar class="mt-5">
				<v-btn class="ma-2" @click="gotoDashboard" value="Dashboard">
				    <v-icon :icon="mdiFileTree" class="mr-1"></v-icon> 
					<span>Dashboard</span>
		  		</v-btn>

				<v-btn class="ma-2 ml-5" @click="closeStoryboard">
					<v-icon :icon="mdiFileLock" class="mr-1"></v-icon>
					<span>End story</span>
				
				</v-btn>
			</v-toolbar>
		</v-layout>
	</v-container>

</template>

<script setup lang="ts">
import { mdiAlphaXCircle, mdiFileLock, mdiFileTree   } from '@mdi/js';

import { watch, nextTick, onBeforeMount, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";

import PanelComponent from '@/components/PanelComponent.vue';
import LibrariesComponent from '@/components/LibrariesComponent.vue';
import ActionRulesComponent from '@/components/ActionRulesComponent.vue';
import hWheelScroll from "@/directives/hScroll";
import { useCommunicsStore } from "@/model/communicsStore";
import { useStoryEditorStore } from "@/model/storyEditorStore";
import type {IActionRule, IPanel } from "@/model/sharedPojos";
import type visibility from '@/directives/visibility';
import { usePanelStore } from '@/model/panelStore';
import { computed } from 'vue';



interface IContextMenuItem {
	title: string;
	value?: string;
	action: () => void;
}

interface IMenuModel {
	xPos: number;
	yPos: number;
	showMenu: boolean;
	items: Array<IContextMenuItem>;
}

const menuModel = reactive<IMenuModel>({
	xPos: 0,
	yPos: 0,
	showMenu: false,
	items: [
		{
			title: 'Delete',
			action: () => editorStore.deletePanelAsync()
		},
		{
			title: 'Clone',
			action: () => editorStore.clonePanelAsync()
		},
	]
});

const isDroppableItemActive = ref(false);
const editorStore = useStoryEditorStore();
const { selectedPanelIdx } = storeToRefs(editorStore);
const {currentPanel} = storeToRefs(usePanelStore());
//const {storyRef} = storeToRefs(editorStore);
const {storyboardRef} = storeToRefs(editorStore);
const {canModify} = storeToRefs(editorStore);
const router = useRouter();
const panels = ref< Array<HTMLElement>>([]);

const hasActionRules = computed(()=>{
	const rules = editorStore.storyRef.actionRules ?? new Array<IActionRule>();
	return rules.length > 0;
})


// watch(storyboardRef, 
// 	() =>{
// 		console.log("cambiato lo storyboard in qualche modo");
// 		selectedElem.scrollIntoView();
// 	});
watch(selectedPanelIdx,
	(newVal, oldVal) => {
		console.log(`${oldVal} -> ${newVal} selectedIdx ${selectedPanelIdx.value}`);
		if (newVal !== -1) {
			panels.value.find(x => x.id === currentPanel.value.uuid)
				?.scrollIntoView({ behavior: "smooth"});
		}
	}, {flush: 'post'});
onBeforeMount(() => {
	editorStore.loadState();
});

// function checkInView(elem: HTMLElement, partial?: boolean) {
// 	const container = storyboardElement.value ?? {} as HTMLElement;
// 	const rectContainer = container.getBoundingClientRect();
// 	const rectElement = elem.getBoundingClientRect();
// 	// console.log(`rectContainer:  ${JSON.stringify(rectContainer)} rectEl: ${JSON.stringify(rectElement)}`);
// 	const maxXElement = rectElement.x + rectElement.width;
// 	const maxYElement = rectElement.y + rectElement.height;
// 	const maxXContainer = rectContainer.x + rectContainer.width;
// 	const maxYContainer = rectContainer.y + rectContainer.height;


// 	if ((maxXElement) < (maxXContainer)
// 		&& (rectElement.x) > (rectContainer.x)
// 		&& (rectElement.y) > (rectContainer.y)
// 		&& (maxYElement) < (maxYContainer)
// 	) {
// 		return true;
// 	}
// 	else {
// 		return false;
// 	}
// 	// var contLeft = container.scrollLeft;
// 	// var contRight = contLeft + rectContainer.width;

// 	// var elemLeft= elem.offsetLeft - container.offsetLeft;
// 	// var elemBottom = elemTop + rectElement.width;

// 	// var isTotal = (elemTop >= 0 && elemBottom <= contHeight);
// 	// var isPart = ((elemTop < 0 && elemBottom > 0) || (elemTop > 0 && elemTop <= container.height())) && partial;

// 	// return isTotal || isPart;
// }

async function handlePanelSelection(ev: Event, newIdx: number) {
	if (! canModify.value) {return;}
	await editorStore.updatePanelSelectionAsync(newIdx);  //maybe update using panel
}

async function updateTitle(event: Event, panel:IPanel) {
	const title = (event.target as HTMLInputElement).value;
	console.log(`update title ${title}`)
	panel.title = title ?? "";
	await editorStore.updateStoryAsync();
}

function handleDragStart(event: DragEvent, idx: number): void {
	if (!canModify.value) { return; }
	console.log(`drag at ${idx} -> ${editorStore.getPanelAt(idx).uuid}`);
	event.dataTransfer?.setData("dragging-idx-panel", idx.toString());
}
function handleDragEnter(event: DragEvent) { event.preventDefault(); }

function handleDrag(event: DragEvent) {
	//console.log(`dragging [${event.x}, ${event.y}]`);

}
function handleDragOver(event: DragEvent) {
	event.preventDefault();
	console.log(`drop over`);
}
function onDragLeave() {
	isDroppableItemActive.value = false;
}

async function handleDrop(event: DragEvent, idx: number): Promise<void> {
	console.log(`drop at ${idx} -> ${editorStore.getPanelAt(idx).uuid}`);
	if (!canModify.value) { return; }
	const dt = event.dataTransfer ?? new DataTransfer();
	if (dt.getData("dragging-idx-panel")) {
		const fromIdx = Number(dt.getData('dragging-idx-panel') || -1);
		console.log(`from ${fromIdx} -> ${idx}`);
		await editorStore.movePanelAsync(fromIdx, idx);
		return;
	}
	if (dt.getData("add-content")) {
		const content = JSON.parse(dt.getData("add-content"));
		console.log(`what drop: ${JSON.stringify(content)}`);
		await editorStore.processAddContent(content);
	}


	
	//	const ev = JSON.parse(event.dataTransfer?.getData('panel-item'));
	//	console.log(`drop event: ${event.dataTransfer?.getData('panel-item') }`);
	//isDroppableItemActive.value = false
}

async function handleContextMenu(ev: MouseEvent, idx: number): Promise<void> {
	await editorStore.updatePanelSelectionAsync(idx);
	console.log("context menu!!!!")
	ev.preventDefault();
	menuModel.showMenu = false;
	menuModel.xPos = ev.clientX;
	menuModel.yPos = ev.clientY;
	await nextTick();
	menuModel.showMenu = true;
}

function gotoDashboard(){
	editorStore.clear();
	router.back();

}

async function closeStoryboard(ev: MouseEvent):Promise<void>{
	await editorStore.endStory();
}
</script>



<style scoped lang="scss">


$max-height-storyboard: 850px;
$min-width-storyboard: 850px;
$max-width-chat: 350px;
$min-width-chat: 350px;
.storyboard {
	display: flex;
	//margin-top: 5rem;
	flex-direction: row;
	max-height: $max-height-storyboard;
	min-width: $min-width-storyboard;
	height: auto;
	min-height: 1rem !important;
	align-items: stretch;
	column-gap: 10px;
	overflow-x: auto;
	overflow-y:hidden;
}
.rules {
	max-height: $max-height-storyboard;
	max-width:  $max-width-chat;
	min-width:  $max-width-chat;


}
.caption {
	min-width: 100px;
	width:auto;
	width: $panel-max-width;
	font-family: $text-font-family;
	font-size: $caption-text-size;
	border-radius: 0.5rem;
	border: 0;
  	padding: 1rem;
	
}
.caption:focus {
	box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.25);
	background-color: $selection-highlight-3;
  	outline: 0;
}
.library {
	height:auto;
	min-width: 950px;
	min-height: 0.2rem;
	overflow-x: auto;
	overflow-y:hidden;
}
.selectedPanel {
	background-color:$selection-highlight-3;
}
.overlay {
	position: absolute;
	// z-index: 10;
}
.text-debug {
	color: rgb(10, 214, 71);
	font-size: 0.60rem;
}
</style>