<template>
	<div class="my-balloon obj" ref="movableContent">
		<div class="toolbar" v-show="isSelected">
			<v-icon class="rotation"  @mousedown="startRotation" :icon="mdiRotateLeft" />
			<!-- to handle a timed double click https://stackoverflow.com/questions/41303982/vue-js-how-to-handle-click-and-dblclick-events-on-same-element-->
			<v-icon class="tail"  @mouseup="changeOrientation" :icon="mdiChat" />
			<v-icon class="b-style" :icon="mdiThoughtBubbleOutline" @mouseup="changeBalloonStyle"  />
		</div>
		<div :class="classRenderer" 
			class="paragraph"
			v-if="!isEditing"
			@mousedown="handleStartMove"
			@dblclick = "toggleEdit = !toggleEdit"
			>{{contentProp.text}}</div>
		<input 
				ref="inputTextBalloon"
				type="text"
				:value="balloonText"
				v-if="isEditing"
				@keyup.enter="$ev => updateText($ev)"
				@click.stop
				@blur="handleLostFocus"
				@mousedown.stop
				@startDrag.stop
				@drag.stop
			>
		<div class="supertail"></div>
		
	</div>
	<!-- <span class="debug">{{ output }}</span>
	<span class="debug">{{ output2 }}</span> -->
</template>

<script setup lang="ts">


import { mdiChat } from '@mdi/js';
import { mdiRotateLeft } from '@mdi/js';
import { mdiThoughtBubbleOutline } from '@mdi/js';
import { computed, nextTick, onMounted, ref, reactive } from 'vue';
import type { IBalloon, IContent } from '@/model/sharedPojos';
import { usePanelStore } from "@/model/panelStore";
import assert from 'assert';
interface Coords {
	x: number;
	y: number;
}

interface IProps {
	contentProp: IBalloon;
};

class TransformationMatrix {
	static readonly identity: TransformationMatrix = {
		translateX: 0,
		translateY: 0,
		rotation: 0,
		skewX: 0,
		scaleX: 1,
		scaleY: 1
	} as TransformationMatrix;

	translateX: number;
	translateY: number;
	rotation: number;
	skewX: number;
	scaleX: number;
	scaleY: number;

	constructor() {
		this.translateX = 0;
		this.translateY  = 0;
		this.rotation = 0;
		this.scaleX = 1;
		this.scaleY = 1;
		this.skewX = 0;
	}

	addTranslation(dx: number, dy: number): void {
		this.translateX += dx;
		this.translateY += dy;
	}


	toMatrixString(): string {
		return "".concat(
			`translate(${this.translateX}px, ${this.translateY}px)`,
			`rotate(${this.rotation}deg)`,
			`scale(${this.scaleX}, ${this.scaleY})`);
	}
	static decomposeMatrixOf(el: Element): TransformationMatrix {
		const { transform } = getComputedStyle(el);
		const matrixString = transform;
		const mat = (matrixString.match(/-?\d+\.?\d+|\d+/g) || [1, 0, 0, 1, 0, 0])
			.map(x => Number(x));
		let scaleX = Math.sqrt(Math.pow(mat[0], 2) + Math.pow(mat[1], 2));
		if (scaleX !== undefined) {
			mat[0] /= scaleX;
			mat[1] /= scaleX;
		};
		let skewX = mat[0] * mat[2] + mat[1] * mat[3];
		if (skewX !== undefined) {
			mat[2] -= mat[0] * skewX;
			mat[3] -= mat[1] * skewX;
		}
		let scaleY = Math.sqrt(Math.pow(mat[2], 2) + Math.pow(mat[3], 2));
		if (scaleY !== undefined) {
			mat[2] /= scaleY;
			mat[3] /= scaleY;
			skewX /= scaleY;
		}
		if (mat[0] * mat[3] < mat[1] * mat[2]) {
			mat[0] *= -1;
			mat[1] *= -1;
			skewX *= -1;
			scaleX *= -1;
		}
		const retVal = new TransformationMatrix();
		const degrees = 180 / Math.PI;
		retVal.translateX = mat[4];
		retVal.translateY = mat[5];
		retVal.rotation = Math.atan2(mat[1], mat[0]) * degrees;
		retVal.skewX = Math.atan(skewX) * degrees;
		retVal.scaleX = scaleX;
		retVal.scaleY = scaleY;
		console.log(`matrixString? ${JSON.stringify(matrixString)} -> ${JSON.stringify(retVal)}`);	
		return retVal;
	}
}

const props = defineProps<IProps>();
const panelStore = usePanelStore();
const inputTextBalloon = ref({} as HTMLInputElement);
const toggleEdit = ref(false);
const balloonText = ref(props.contentProp.text);
const orientation = ref(props.contentProp.tailOrientation);
const isEditing = computed(() => {
	return toggleEdit.value;
});
const isSelected = computed(()=> {
	console.log(`is selected? ${panelStore.currentEditingElement.uuid === props.contentProp.uuid}`)
	return panelStore.currentEditingElement.uuid === props.contentProp.uuid
})

const toolbarClass = computed(() => isSelected.value  ? "toolbarVisible" : "");

const classRenderer = computed(() => {
	let output = "content ";
	output = output.concat(`${props.contentProp.style}-bubble-border ${orientation.value} `);
	//output = output.concat(" bubble-border right")
	//output = output.concat(contentSelectionClass.value);
	console.log(`class: ${output}`);
	
	return output;
});

const emits = defineEmits<{
	(e:"selectObj", content: IContent): void
}>();

const movableContent = ref<HTMLElement>();
const transformableTarget = ref({} as HTMLElement);
const ableContainer = ref({} as HTMLElement);
const initTargetDims = {w:0.0, h:0.0};
const lastInteraction = {
	x: 0,
	y: 0, 
	cx: 0.0,
	cy: 0.0,
	elWidth: 0.0,
	elHeight: 0.0,
}
const transformReactive = reactive({matrix: TransformationMatrix.identity});
const toMatrixString = computed<string>(() => {
	return "".concat(
		`translate(${transformReactive.matrix.translateX}px, ${transformReactive.matrix.translateY}px)`,
		`rotate(${transformReactive.matrix.rotation}deg)`,
		`scale(${transformReactive.matrix.scaleX}, ${transformReactive.matrix.scaleY})`);
});

function changeOrientation(ev:Event){
	//console.log("orientation 0 " + orientation.value);
	if (!isSelected.value) {return;}
	if (orientation.value === "") { orientation.value = "bottom-right" ;}
	else if (orientation.value === "bottom-right") { orientation.value = "right"; }
	else if (orientation.value === "right") { orientation.value = "top-right"; }
	else if (orientation.value === "top-right") { orientation.value = "top-left"; }
	else if (orientation.value === "top-left") { orientation.value = "left"; }
	else if (orientation.value === "left") { orientation.value = ""; }
	panelStore.changeTailOrientationAsync(orientation.value, props.contentProp);
	console.log("orientation 1 " + props.contentProp.tailOrientation);
}
async function changeBalloonStyle(ev:Event): Promise<void>{
	panelStore.changeTextStyle(props.contentProp);
}


let numClicks = 0;
function detectClick(ev: Event) {
	numClicks++;
	if (numClicks === 1) {          // the first click in .2s
		setTimeout(function () {
			switch (numClicks) {     // check the event type
				case 1:
					console.log(`One click`);
					break;
				default:
					console.log(`double click`);
					toggleEdit.value = true;
			}
			numClicks = 0;               // reset the first click
		}, 200);                              // wait 0.2s
	} // if
}  // detectClick function


onMounted(async () =>{
	nextTick();
	ableContainer.value = movableContent.value?.parentElement ?? {} as HTMLElement;
	const rect  = (movableContent.value?.querySelector(".content") as HTMLElement)
		?.getBoundingClientRect();
	initTargetDims.w = rect.width ?? 0.0;
	initTargetDims.h = rect.height ?? 0.0;
	console.log(`xPos ${props.contentProp.xPosition}`);
	transformReactive.matrix.translateX = props.contentProp.xPosition;
	transformReactive.matrix.translateY = props.contentProp.yPosition;
	transformReactive.matrix.rotation = props.contentProp.rotationAngle;
	console.log(`on mount balloon invoke toMatr ${toMatrixString.value}`);
	if (movableContent.value) {
		movableContent.value.style.transform = toMatrixString.value;
	}
});



async function handleStartMove(ev: MouseEvent): Promise<void> {
	ev.preventDefault();
	const target = (ev.currentTarget as HTMLElement).parentElement as HTMLElement ;
	//const rect = (target as Element).getBoundingClientRect();
	lastInteraction.x = ev.clientX as number;
	lastInteraction.y = ev.clientY as number;
	transformReactive.matrix = TransformationMatrix.decomposeMatrixOf(target)
	transformableTarget.value = target;
	//console.log(`handleStartMove movableContent == transformable ${transformableTarget.value === ableContainer.value}`)
	ableContainer.value.addEventListener("mousemove", handleMove);
	ableContainer.value.addEventListener("mouseup", handleEndMove);
	window.addEventListener("mouseup", handleEndMove);
	panelStore.setEditingContent(props.contentProp);
	await nextTick();
	emits("selectObj", props.contentProp);
}
function handleMove(ev: MouseEvent){
	ev.preventDefault();
	if (transformableTarget.value === undefined ||
		transformableTarget.value === {} as HTMLElement) { return; }
	transformReactive.matrix.addTranslation(
		ev.clientX - lastInteraction.x,
		ev.clientY - lastInteraction.y)
//	output.value = `traslate of x: ${(ev.clientX as number - lastInteraction.x)}`
	transformableTarget.value.style.transform = transformReactive.matrix.toMatrixString();
//	output.value = `rectangle: ${JSON.stringify(transformableTarget.value.getBoundingClientRect())}`
	lastInteraction.x = ev.clientX as number;
	lastInteraction.y = ev.clientY as number;
//	console.log(`after transform: ${JSON.stringify(transformReactive.matrix)}`);
}

function handleEndMove(ev: MouseEvent){
	ev.preventDefault();
	ev.stopImmediatePropagation();
	panelStore.updateGeometry(
		{x:transformReactive.matrix.translateX,
		y:transformReactive.matrix.translateY});
	detachHandlers(handleMove, handleEndMove);
}
function startRotation(ev: MouseEvent): void {
	ev.preventDefault();
	const target = (ev.currentTarget as HTMLElement).parentElement?.parentElement as HTMLElement; //movableContent
	if (target === undefined) { return; }
	const rect = (target).getBoundingClientRect();
	lastInteraction.cx = rect.left + rect.width / 2;
	lastInteraction.cy = rect.top + rect.height / 2;
	transformReactive.matrix = TransformationMatrix.decomposeMatrixOf(target);
	transformableTarget.value = target;
	ableContainer.value.addEventListener("mousemove", handleRotation);
	ableContainer.value.addEventListener("mouseup", stopRotation);
	window.addEventListener("mouseup", stopRotation);
}
function handleRotation(ev: MouseEvent) {
	ev.preventDefault();
	const cursorPoint = { x: ev.clientX, y: ev.clientY }
	const newAngle = (180 / Math.PI) * Math.atan2(cursorPoint.x - lastInteraction.cx, - (cursorPoint.y - lastInteraction.cy));
	transformReactive.matrix.rotation = newAngle;
	//output2.value = "de=" + newAngle;
	transformableTarget.value.style.transform = transformReactive.matrix.toMatrixString();
}
function stopRotation(ev: MouseEvent) {
	panelStore.updateGeometry({rot:transformReactive.matrix.rotation});
	detachHandlers(handleRotation, stopRotation);
}
function startScale(ev: MouseEvent) {
	ev.preventDefault();
	const parent = (ev.currentTarget as HTMLElement).parentElement as HTMLElement;
	const target = parent.querySelector(".content") as HTMLElement;
	if (target ===  undefined) {return;}
	const rect = (target).getBoundingClientRect();
	lastInteraction.cx = rect.left + rect.width / 2;
	lastInteraction.cy = rect.top + rect.height / 2;
	lastInteraction.x = ev.clientX;
	lastInteraction.y = ev.clientY;
	lastInteraction.elWidth = rect.width;
	lastInteraction.elHeight = rect.height;
	transformReactive.matrix = TransformationMatrix.decomposeMatrixOf(target);
	transformableTarget.value = target;
	ableContainer.value.addEventListener("mousemove", handleScale);
	ableContainer.value.addEventListener("mouseup", stopScale);
	window.addEventListener("mouseup", stopScale);
}
function handleScale(ev: MouseEvent) {
	ev.preventDefault();
	const w = lastInteraction.elWidth + (ev.clientX - lastInteraction.x) ;
	const h =  lastInteraction.elHeight + (ev.clientY - lastInteraction.y);
	if (w < initTargetDims.w * 0.25 
		|| w > initTargetDims.w * 1.5
		|| h < initTargetDims.h * 0.25 
		|| h > initTargetDims.h * 1.5) {return; }
	//output2.value = `w ${w}`;
	transformableTarget.value.style.width = `${w}px`;
	//target.style.height = `${zoom.h}px`;
	//output.value = `rect ${JSON.stringify(lastInteraction)}`;
}
function stopScale(ev: MouseEvent) {
	detachHandlers(handleScale, stopScale);	
}
function detachHandlers(mouseMoveHandler: any, mouseUpHandler: any){
	transformableTarget.value = {} as HTMLElement;
	ableContainer.value.removeEventListener("mousemove", mouseMoveHandler);
	ableContainer.value.removeEventListener("mouseup", mouseUpHandler);
	window.removeEventListener("mouseup", mouseUpHandler);
}
async function handleFocus(ev: FocusEvent & { target: HTMLInputElement }): Promise<void> {
	console.log(`got focus at ${panelStore.currentEditingElement.uuid}`);
	const {target} = ev;
	target.select();
	toggleEdit.value = true;
}
async function handleLostFocus(ev: FocusEvent): Promise<void> {
	console.log(`lost focus at ${panelStore.currentEditingElement.uuid} `);
	toggleEdit.value = false;
	const target = ev.target as HTMLInputElement;
	console.log(`target value ${target.value}, prop ${props.contentProp.text}`);

	await panelStore.updateText(target.value);
}
async function updateText(ev: UIEvent):Promise<void> {
	const eventTarget = ev.target as HTMLInputElement;
	console.log(`updating text target value ${eventTarget.value}, prop ${balloonText.value}`);
	await panelStore.updateText(eventTarget.value);
	balloonText.value = eventTarget.value;
	toggleEdit.value = false;
}
</script>


<style scoped lang="scss">


$thought-bg-color:rgb(255, 255, 255);
$caption-bg-color: rgba(255, 255, 255, 0.9);
$bubble-bg-color:  rgba(255, 255, 255, 0.95);
//$bubble-border-color: rgba(255, 1, 0, 0.95);
$bubble-border-color: $text-base-color-1;

%baloon-bubble-border {
	position:relative;
 	padding:5px;
  	margin:1em 0 3em;
  	border:3px solid $bubble-border-color;
  	color:#333;
 	background:#fff;
  	/* css3 */
  	-webkit-border-radius:10px;
  	-moz-border-radius:10px;
  	border-radius:10px;
}
%baloon-tail-outer {
	content:"";
  	position:absolute;
  	bottom:-19px; /* value = - border-top-width - border-bottom-width */
  	left:10px; /* controls horizontal position */
  	border-width:16px 16px 0;
  	border-style:solid;
  	border-color:$bubble-border-color transparent;
  	/* reduce the damage in FF3.0 */
  	display:block;
  	width:0;
}
%baloon-tail-inner {
	content:"";
  	position:absolute;
  	bottom:-14px; /* value = - border-top-width - border-bottom-width */
  	left:11px; /* value = (:before left) + (:before border-left) - (:after border-left) */
  	border-width:15px 15px 0;
  	border-style:solid;
  	border-color:#fff transparent;
  	/* reduce the damage in FF3.0 */
  	display:block;
  	width:0;
}
.content {
	// border-style: solid;
	// border-color: rgb(81, 51, 142);
	background-color: $bubble-bg-color;
	color: $text-base-color;
	padding-left: .2rem;
	padding-right: .2rem;
	width: auto;
	height: auto;
	min-width: 200px;
	min-height: 40px;
	transform: scale(1, 1);
	font-size: $text-base-size;
	font-family: $text-font-family;
	z-index: 2;
}
.caption {
	background-color: $caption-bg-color;
	color: $text-base-color;
}

/* ============================================================================================================================
== BUBBLE WITH A BORDER AND TRIANGLE
** ============================================================================================================================ */

/* THE SPEECH BUBBLE
------------------------------------------------------------------------------------------------------------------------------- */
.speech-bubble-border {
	@extend %baloon-bubble-border;
}

/* Variant : for left positioned bubble
------------------------------------------ */
.speech-bubble-border.left {
  margin-left:30px;
}

/* Variant : for right positioned bubble
------------------------------------------ */

.speech-bubble-border.right {
  margin-right:30px;
}

/* THE TAIL
------------------------------------------------------------------------------------------------------------------------------- */
/* creates outer tail*/
.speech-bubble-border:before {
	@extend %baloon-tail-outer;
}

/* creates inner tail */
.speech-bubble-border:after {
	@extend %baloon-tail-inner;
}
/* Variant: bottom right */
.speech-bubble-border.bottom-right:before{
	left: auto;
	right: 10px;
}
.speech-bubble-border.bottom-right:after{
	left: auto;
	right: 11px;
}


/* Variant : top left
------------------------------------------ */
/* creates the outer tail  */
.speech-bubble-border.top-left:before {
  top:-18px; /* value = - border-top-width - border-bottom-width */
  bottom:auto;
  left:9px;
 // right:40px; /* controls horizontal position */
  border-width:0 16px 16px;
}

/* creates inner tail */
.speech-bubble-border.top-left:after {
  top:-13px; /* value = - border-top-width - border-bottom-width */
  bottom:auto;
  left:10px;
  //right:47px; /* value = (:before right) + (:before border-right) - (:after border-right) */
  border-width:0 15px 15px;
}

/* Variant : top right
------------------------------------------ */
/* creates the outer tail  */
.speech-bubble-border.top-right:before {
  top:-18px; /* value = - border-top-width - border-bottom-width */
  bottom:auto;
  left:auto;
  right:9px;
 // right:40px; /* controls horizontal position */
  border-width:0 16px 16px;
}

/* creates inner tail */
.speech-bubble-border.top-right:after {
  top:-13px; /* value = - border-top-width - border-bottom-width */
  bottom:auto;
  left:auto;
  right:10px;
  //right:47px; /* value = (:before right) + (:before border-right) - (:after border-right) */
  border-width:0 15px 15px;
}

/* Variant : left
------------------------------------------ */

/* creates outer tail*/
.speech-bubble-border.left:before {
  top:6px; /* controls vertical position */
  bottom:auto;
  left:-20px; /* value = - border-left-width - border-right-width */
  border-width:10px 20px 10px 0;
  border-color:transparent $bubble-border-color;
}

/* creates inner tail */
.speech-bubble-border.left:after {
  top:10px; /* value = (:before top) + (:before border-top) - (:after border-top) */
  bottom:auto;
  left:-12px; /* value = - border-left-width - border-right-width */
  border-width:6px 12px 6px 0;
  border-color:transparent #fff;
}

/* Variant : right
------------------------------------------ */

/* creates outer tail*/
.speech-bubble-border.right:before {
  top:6px; /* controls vertical position */
  bottom:auto;
  left:auto;
  right:-20px; /* value = - border-left-width - border-right-width */
  border-width:10px 0 10px 20px;
  border-color:transparent $bubble-border-color;  /* $bubble-bg-color*/
}

/* creates inner tail */
.speech-bubble-border.right:after {
  top:10px; /* value = (:before top) + (:before border-top) - (:after border-top) */
  bottom:auto;
  left:auto;
  right:-12px; /* value = - border-left-width - border-right-width */
  border-width:6px 0 6px 12px;
  border-color:transparent #fff;
}


.thought-bubble-border {
	@extend %baloon-bubble-border;
	background-color:$thought-bg-color;
	border: 3px solid $thought-bg-color;
	padding: 0.5rem;
	border-radius: 40px;
	align-items: center;
	justify-content: center;
	text-align: center;
}


// .thought-bubble-border:after {
// 	width: 44px;
// 	height: 44px;
// 	top: -12px;
// 	left: 28px;
// 	border-radius: 50%;
// 	box-shadow: -50px 30px 0 -12px forestgreen;
// }
.thought-bubble-border:before {
	content: "";
	background-color:$thought-bg-color;
	border-radius: 50%;
	display: block;
	position: absolute;
	z-index: -1;
	bottom: 0px;
	right: 0px;
	width: 30px;
	height: 30px;
	box-shadow: 
		40px -17px 0 0 $thought-bg-color,
		//12px -10px 0 -2px $thought-bg-color,
		-12px -2px 0 -8px $thought-bg-color,
		-28px 3px 0 -10px $thought-bg-color,
		-42px 8px 0 -12px $thought-bg-color,
		20px 15px 0 -2px $thought-bg-color;
		// 90px 25px 0 -8px $thought-bg-color,
		// 80px -24px 0 -8px $thought-bg-color,
		// 150px -20px 0 -7px $thought-bg-color,
		// 160px -22px 0 -7px $thought-bg-color,
		// 85px 22px 0 -8px $thought-bg-color;
		

}

/* THE thought BUBBLE
------------------------------------------------------------------------------------------------------------------------------- */
.thought-bubble-border {
	@extend %baloon-bubble-border;
}

/* Variant : for left positioned bubble
------------------------------------------ */
.thought-bubble-border.left {
  margin-left:30px;
}

/* Variant : for right positioned bubble
------------------------------------------ */

.thought-bubble-border.right {
  margin-right:30px;
}

/* THE TAIL
------------------------------------------------------------------------------------------------------------------------------- */
/* creates outer tail*/
.thought-bubble-border:before {

}

/* creates inner tail */
.thought-bubble-border:after {
	@extend %baloon-tail-inner;
}
/* Variant: bottom right */
.thought-bubble-border.bottom-right:before{
	left: auto;
	right: 10px;
}
.thought-bubble-border.bottom-right:after{
	left: auto;
	right: 11px;
}


/* Variant : top left
------------------------------------------ */
/* creates the outer tail  */
.thought-bubble-border.top-left:before {
  top:-2px; /* value = - border-top-width - border-bottom-width */
  bottom:auto;
  left:-13px;
  right:0px; /* controls horizontal position */
//  border-width:0 16px 16px;
}

/* creates inner tail */
.thought-bubble-border.top-left:after {
  top:-13px; /* value = - border-top-width - border-bottom-width */
  bottom:auto;
  left:10px;
  right:47px; /* value = (:before right) + (:before border-right) - (:after border-right) */
  //border-width:0 15px 15px;
}

/* Variant : top right
------------------------------------------ */
/* creates the outer tail  */
.thought-bubble-border.top-right:before {
  top:-18px; /* value = - border-top-width - border-bottom-width */
  bottom:auto;
  left:auto;
  right:9px;
 // right:40px; /* controls horizontal position */
  //border-width:0 16px 16px;
}

/* creates inner tail */
.thought-bubble-border.top-right:after {
  top:-13px; /* value = - border-top-width - border-bottom-width */
  bottom:auto;
  left:auto;
  right:10px;
  //right:47px; /* value = (:before right) + (:before border-right) - (:after border-right) */
  //border-width:0 15px 15px;
}

/* Variant : left
------------------------------------------ */

/* creates outer tail*/
.thought-bubble-border.left:before {
  top:6px; /* controls vertical position */
  bottom:auto;
  left:-20px; /* value = - border-left-width - border-right-width */
  //border-width:10px 20px 10px 0;
  //border-color:transparent $bubble-border-color;
  //border-color:transparent #691616;
}
/* creates inner tail */
.thought-bubble-border.left:after {
  top:10px; /* value = (:before top) + (:before border-top) - (:after border-top) */
  bottom:auto;
  left:-12px; /* value = - border-left-width - border-right-width */
  border-width:6px 12px 6px 0;
  border-color:transparent $bubble-bg-color;
}

/* Variant : right
------------------------------------------ */

/* creates outer tail*/
.thought-bubble-border.right:before {
  top:6px; /* controls vertical position */
  bottom:auto;
  left:auto;
  right:-20px; /* value = - border-left-width - border-right-width */
  border-width:10px 0 10px 20px;
  //border-color:transparent $bubble-border-color;  /* $bubble-bg-color*/
  border-color:transparent #333;  /* $bubble-bg-color*/
}

/* creates inner tail */
.thought-bubble-border.right:after {
  top:10px; /* value = (:before top) + (:before border-top) - (:after border-top) */
  bottom:auto;
  left:auto;
  right:-12px; /* value = - border-left-width - border-right-width */
  //border-width:6px 0 6px 12px;
  //border-color:transparent $bubble-bg-color;
}











.paragraph{
	inline-size: 1.4rem;
	word-wrap: break-word;
}

.contentSelected {
	//border: 2px solid rgb(217, 246, 71);
	z-index: 3;
	display: block !important;
}
.my-balloon {
	display:flex;
	position: absolute;
	top: 0px;
	left: 0px;
	transform: translate(0px, 0px);
}
.obj {
	
}
.toolbar {
	position: absolute;
	top: -30px !important;
	left: 0px !important;
	font-size:1.2rem;
}
.node{
	display: hidden ;
}

.rotation {
	color:$selection-highlight-1;
	// border: 2px solid ;
	// border: 2px solid rgb(217, 246, 71);
	// background-color: rgba(217,
	// 			246,
	// 			71, 0.5);
	
	//transform: translate(-50%, -50%);
	// box-sizing: border-box;
	// cursor: url(../assets/arrows-rotate-solid.svg),
	// 	auto;
	z-index: 10;
}

.tail {
	color:$selection-highlight-1;
}
.b-style{
	color:$selection-highlight-1;
}

.resize-node {
	display: none;
	position: absolute;
	width: 20px !important;
	height: 20px !important;
	border: 2px solid rgb(217, 246, 71);
	background-color: rgba(217,
		246,
		71, 0.5);
	box-sizing: border-box;
	transform: translate(-50%, -50%);
	z-index: 11;
	cursor: se-resize;
}
</style>