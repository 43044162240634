//  import Vue from 'vue';



// function scrollHorizontally(e: Event) {
// 	let element = e.target;
// 	while (!element.getAttribute("v-hscroll-main")){}
// 		element = element.parentNode;

// 	let delta = Math.max(-1, Math.min(1, (e. || -e.detail)));
// 	element.scrollLeft -= (delta * 40);

// 	if ((delta > 0 && element.scrollLeft > 0) ||
// 		(delta < 0 && element.offsetWidth + element.scrollLeft < element.scrollWidth)) {
// 		e.preventDefault();
// 	}
// };

// function waitForSelector(el, binding, vn) {
// 	const newEl = el.querySelector(binding.value);

// 	if (newEl)
// 		attachToElement(newEl);
// 	else setTimeout(() => waitForSelector(el, binding, vn), 100);

// }

// function attachToElement(el) {
// 	el.setAttribute("v-hscroll-main", "1");
// 	el.addEventListener('wheel', scrollHorizontally, false);
// }
// // enables v-focus in templates
// const vHscroll = {
// 	mounted: (el:Vue) => el.focus()
// }


const hWheelScroll = {
  mounted: (el:HTMLElement) => {
    el.style.cursor = 'pointer';
    el.addEventListener('wheel', (ev: WheelEvent) => {
		ev.preventDefault();
		console.log(`delta [x,y]: [${ev.deltaX} ${ev.deltaY}] ${el.scrollLeft}`);
		el.scrollLeft += ev.deltaY;

    });
  },
};

export default hWheelScroll;