<template>
	<v-card class="item">
		<div v-if="props.template!==undefined" class="details" @click="$emit('editFromTemplate', props.template)">
			<v-icon :icon="mdiMovieEditOutline"  small ></v-icon>
			<p class="text-h5">{{ template?.title}}</p>
			<p class="text-caption" style="color: #000">[{{ template?.tags?.join() }}]</p>
			<p class="text-caption">from {{ printTemplateAuthor()}}</p>
			<p class="text-caption">contains {{ printTemplateStory() }}</p>
			
			<slot></slot>
		</div>
		<div v-else 
			class="details" 
			:class="editableClass"
			@click="handleClickDetails" 
			@contextmenu.prevent="handleContextMenu">
			<v-icon :icon="editableIcon" :class="editableClass" small/>
			<p class="text-h5">{{ story?.title }}</p>
			<p v-if="!props.closed" class=" text-caption">{{printStoryDetail() }}</p>
			<slot></slot>
		</div>
		<v-menu 
			class="menu" 
			v-model="menuModel.showMenu"
			:style="{ top: menuModel.yPos + 'px', left: menuModel.xPos + 'px' }">
			<v-list>
				<v-list-item v-for="(i, index) in menuModel.items" :key="index" @click="i.action()">
					<v-list-item-title>{{ i.title }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu><!-- <v-btn prepend-icon="mdi-book-open" @click="showInfo">details

		</v-btn> -->
	</v-card>
	
</template>

<script setup lang="ts">
import { mdiFileLockOpenOutline, mdiFileLock, mdiMovieEditOutline  } from '@mdi/js';
import { computed, reactive } from 'vue';
import type {IStory, IStoryTemplate, IUser} from "@/model/sharedPojos";
import moment from 'moment';
import type { IContextMenuItem, IMenuModel } from '@/model/menuIModel';
import { useCommunicsStore } from '@/model/communicsStore';

const store = useCommunicsStore();

const menuModel = reactive<IMenuModel>({
	xPos: 100,
	yPos: 150,
	showMenu: false,
	items: [
		{
			title: 'Delete',
			action: () => store.deleteStoryAsync(props?.story)
		},
	],
});

const props = defineProps<{
	story?:IStory ,
	template?: IStoryTemplate,
	closed?: boolean}>();
const emits = defineEmits<{
	(e:'editStory', args: IStory ):void,
	(e:'editFromTemplate', args: IStoryTemplate): void
}>();
const editableClass = computed(()=>
	props.closed == true? 
		'notEditableColor': 'editableColor'		
);
const editableIcon = computed(()=>
	props.closed == true?
		mdiFileLock : mdiFileLockOpenOutline
)
function showInfo(){
	console.log(`show details of ${props?.story?.title}`)

}

function printTemplateAuthor():string {
	console.log(`props ${props?.template?.title}`);
	const users = props?.template?.owners ?? new Array<IUser>();
	return `${users
		.map(x => x.email)
		.join()}`;
}
function printTemplateStory(){
	const story = props?.template?.story ?? { title: "" } as IStory;
	return `-> ${story.title}`;
}
function printStoryDetail(): string{
	const date = props?.story?.environment?.createdAt ??  new Date();
	return `last edit on ${format(date)}`;

}
function format(date:Date): string{
	return moment(date).format('D MMMM YY [at] h:mm');
}
function handleContextMenu(ev: MouseEvent): void {
	menuModel.showMenu = true;
	menuModel.xPos = ev.clientX;
	menuModel.yPos = ev.clientY;

}
function handleClickDetails(ev: MouseEvent): void{
	if (props.story) {
		emits('editStory', props.story as IStory);
	}
}

</script>

<style scoped lang="scss">

$item-selection: rgba($selection-highlight-3, 0.45);
$item-closed-color: rgba(255,0,0,1);

.v-icon {
  color: $text-base-color-1;
  //display:  "flex-inline";
} 
.editableColor{
	color: $text-base-color-1;
}
.notEditableColor {
	color: $text-base-color-4;
}
.item {
	margin-top: 2rem;
	display: flex;
	min-width: 0;
	//max-width: 20rem;
}

	//color: $text-base-color-1;


.item:hover {
	background-color: $item-selection;
}
.details {
	flex: 1;
	display: grid;
	grid-template-columns: [first] 40px [second] auto [last-line];
	margin-left: 1rem;
	//background-color: blueviolet; 
	cursor: pointer;
}
.details > p {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	
//	background-color: blueviolet; 
}
.text-caption {
	grid-column-start: 2;

}
i {
	display: flex;
	place-items: center;
	place-content: center;
	width: 32px;
	height: 32px;

	color: var(--color-text);
}

h3 {
	font-size: 1.2rem;
	font-weight: 500;
	margin-bottom: 0.4rem;
	color: var(--color-heading);
}
</style>
