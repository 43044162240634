import { reactive } from "vue";
import {computed} from "@vue/reactivity";
import { defineStore} from 'pinia';
import axios from 'axios';

import type * as dt from './sharedPojos';
import type { IAppResources } from './appResources';
import { anonymousUser } from "./appStore";
import { useStoryEditorStore } from "./storyEditorStore";
import {safeJsonParse} from "../utils";
import { useServices } from "@/composable/useServices";
import { es } from "@faker-js/faker";
import { useChatStore } from "./chatStore";
import { useStorage } from '@vueuse/core';




interface IappState{
	stories: Array<dt.IStory>;
	storyTemplates: Array<dt.IStoryTemplate>;
	communicsUsr?: dt.IUser;
	currentResources: IAppResources;

}

const appState:IappState = reactive<IappState>({
	stories: [],
    storyTemplates: [],
    currentResources: {
        backgrounds: [],
        characters: [],
        sceneObjects:[],
        predefinedTexts: [],
        emojis: [], 
    },
});


export const useCommunicsStore = defineStore("communicStore", () =>{
	const localData = useStorage('local_data', (new Array<{tag:string, id:string}>()));
	const data:IappState = reactive<IappState>({
		stories: new Array<dt.IStory>(),
		storyTemplates: new Array<dt.IStoryTemplate>(),
		currentResources: {
			backgrounds: [],
			characters: [],
			sceneObjects:[],
			emojis: [],
			predefinedTexts: []
		},
		communicsUsr: undefined,
	} as IappState);

	const  currentUsr = computed(() => data.communicsUsr as dt.IUser);
	const logged = computed(() => data.communicsUsr?.id? true: false);
	const currentResources = computed(()=>data.currentResources);
	const stories = computed(()=> data.stories.filter(x => x.environment.closedAt === undefined));
	const storyTemplates = computed(()=> data.storyTemplates);
	const closedStories = computed(()=> data.stories.filter(x => x.environment.closedAt !== undefined));
	
	async function fetchUserStories(usr: dt.IUser): Promise<void>{
		data.communicsUsr = usr;
		console.log(`usr id? ${JSON.stringify(usr.id)}`)
		
		try {
			const stories = new Array<dt.IStory>();
			const s = (await axios.get(
			 	'/st/stories',
				{ 
					params: { userID: usr.id },
				 	responseType: "json" 
				}
				
			)).data;
			const obj = JSON.parse(s) as Array<dt.IStory>;
			console.log(`len: ${obj[0]?.title}`);
			stories.push(...obj);
			data.stories = stories;
			await fetchStoryTemplatesAsync();
			useStoryEditorStore().clear();
		
		}
		catch(error){console.log(error);}
	}

	async function fetchStoryTemplatesAsync(filter?: Array<string>): Promise<void>{
		try {
			const usr = data.communicsUsr ?? anonymousUser;
			const f = filter ?? [usr.id, usr.email];
			console.log(`asking a template with filter ${f.join()}`);
			const s = (await axios.get(
		 		'/st/templates',
				{params: {tags: f.join()}}
			)).data;
			const predef = new Array<dt.IStoryTemplate>();
			(JSON.parse(s) as Array<dt.IStoryTemplate>).forEach(x => predef.push(x));
			data.storyTemplates = predef;

		}
		catch (ex) {
			console.log(ex);
		}
	}

	async function fetchAStory(user: dt.IUser, storyID: string):Promise<void>{
		try {
			await clearUser();
			console.log(`fetching a story with userID ${user.id} story: ${storyID}`);
			const d = (await axios.post(
			 	'/st/queryStories',
				{ authorID: user.id,
					storyID: storyID},
				{responseType: "json"})).data;
			const stories = JSON.parse(d) as Array<dt.IStory>;
			data.stories.push(...stories);
			console.log(`find stories: ${stories.length}`);
			if (stories.length === 1){await updateCurrentStoryAsync(stories[0]);}
			else {
				const msg = `find ${stories.length} stories with ${storyID} id `;
				console.error(msg);
				throw new Error(msg);
			}
		}
		catch(error){console.log(error);}
	}

	async function useTemplateAsync(params:{templateID: string, userID:string, storyTag?:string}):Promise<void>{
		try {
			const s = (await axios.post('/st/useTemplate',
				params)).data;
			const story = JSON.parse(s) as dt.IStory;
			data.stories = [story, ...data.stories];
			await updateCurrentStoryAsync(story);
		}
		catch(error) {console.log(error);}
	}

	async function createFromTemplateAsync(user: dt.IUser, templateID: string, storyTag: string): Promise<void>{
		console.log(`edit from a template`);
		if (user !== undefined && user.email !== undefined) {
			await fetchUserStories(user);
				// if (storyTag !== undefined &&
				// 	data.stories.some(x => x.environment.labels.includes(storyTag))) {
				// 		throw new Error(`story with tag ${storyTag} already exists`);
				// }
			const current = localData.value ?? new Array<{tag: string; id: string}>();
			const alreadyDefined = current.find(x => x.tag === storyTag && x.id !== undefined);
			if (alreadyDefined === undefined) {  //any saved data
				await fetchStoryTemplatesAsync([user.email]);
				const idx = data.storyTemplates.findIndex(x => x.id === templateID);
				if (idx < 0) {
					throw new Error(`template with id ${templateID} does not found`);
				}
				const params = {
					templateID: templateID as  string,
					userID: user.id, 
					storyTag: storyTag as string,
				};
				const res = (await axios.post('/st/useTemplate', params)).data;
				const s = JSON.parse(res) as dt.IStory
				data.stories = [s, ...data.stories];
				console.log(`adding storyID to local storage ${s.id}`);
				localData.value.push({id:s.id, tag: storyTag});
			}
			console.log(`editing story id ${JSON.stringify(localData.value)}`)
			const editingID = localData.value.find(x => x.tag === storyTag && x.id !== undefined)
			const story = data.stories.find( x => x.id === editingID?.id);
			if (story === undefined) {throw new Error(`editing story not found in local storage`);}
			await updateCurrentStoryAsync(story);
		}
	}


	async function clearLocalStorage(storyID?:string): Promise<void> {
		const idx = localData.value.findIndex(x => x.id === storyID);	
		if (idx > -1 ){
			localData.value.splice(idx,1);
		}
	}

	 async function loadAllResources(): Promise<Array<dt.IMediaSource>> {
		try {
			const params = { resourceLabel: data.currentResources.resourceTag };
		 	const resources = JSON.parse(
				(await axios.get("/mediaResource/resourcesBySingleTag", 
					{params}))
				.data) as Array<dt.IMediaSource>;
			return resources;
		}
		catch(error) {console.log(error);}
		return [];
	}

	async function updateResourcesAsync(s: dt.IStory): Promise<void>{
		try {
			data.currentResources.resourceTag = s.settings.resourcesNamespace;
			const chatStore = useChatStore();
			chatStore.actionsRef = s.actionRules;
			let resources = await loadAllResources();
			console.log(`before filtering: ${resources.length}`);
			//get binaries
			const {api} = useServices();
			const ids = resources.map(x => x.id)
			await Promise.all(
				ids.map(
					async (x) => await api.getImage2(x, {preload: true})
			));
			
			if (s.actionRules.length !== 0) {
				const nextResourceTag = s.actionRules.find(x => !x.completed)?.resources[0] || "";
				resources = resources.filter(x => x.tags.includes(nextResourceTag));
			}
			
			data.currentResources.backgrounds = resources.filter((x => x.path.includes('/background/')));

			console.log(`backgrounds: ${data.currentResources.backgrounds.length}`)
			data.currentResources.sceneObjects = resources.filter((x => x.path.includes('/object/')));
			data.currentResources.characters = resources.filter((x => x.path.includes('/character/')));
			data.currentResources.emojis = resources.filter((x => x.path.includes('/emoji/')));
			data.currentResources.predefinedTexts = ["empty", "texts"];
			
		}
		catch(error) {console.log(error);}
	}


	async function updateCurrentStoryAsync(s: dt.IStory):Promise<void> {
		const storyEditorStore = useStoryEditorStore();
		storyEditorStore.storyRef = s;
		await updateResourcesAsync(s);
		// try {
			
		// 	const func
		// 	() => {
		// 			data.currentResources.backgrounds = JSON.parse(
		// 				(await axios.get(
		// 					'/mediaResource/backgrounds',
		// 					{ params }))
		// 				.data);
		// 		},
		// 		async () => {data.currentResources.sceneObjects = JSON.parse(
		// 			(await axios.get(
		// 				'/mediaResource/sceneObjs',
		// 				{ params }))
		// 				.data);
		// 		},
		// 		async () => {
		// 			data.currentResources.characters = JSON.parse(
		// 				(await axios.get(
		// 					'/mediaResource/characters',
		// 					{ params }))
		// 					.data);
		// 		},
		// 		async () => {
		// 			data.currentResources.emojis = JSON.parse(
		// 				(await axios.get(
		// 					'/mediaResource/emojis',
		// 					{ params }))
		// 					.data);
		// 		},
		// 		async () => {
		// 			data.currentResources.predefinedTexts =["empty", "texts"] 
		// 		}
		// 	]
		// 	await Promise.all(
		// 		funcArray.map(async(x) => await x()));
		// 	const {api} = useServices();
		// 	const ids = data.currentResources.backgrounds.map(x => x.id)
		// 		.concat(data.currentResources.sceneObjects.map(x => x.id), 
		// 			data.currentResources.characters.map(x => x.id),
		// 			data.currentResources.emojis.map(x => x.id))
		// 	await Promise.all(
		// 		ids.map(
		// 			async (x) => await api.getImage2(x, {preload: true})
		// 	));
		// }
		// catch(error) {console.log(error);}
	}
	async function clearUser(): Promise<void> {
		data.communicsUsr = undefined;
		useStoryEditorStore().clear();
		data.currentResources = {
			backgrounds: [],
			characters: [],
			sceneObjects:[],
			emojis: [],
			predefinedTexts: []
		} as IAppResources;
		data.stories = new Array<dt.IStory>();
		//send message to backend
		//should I remove logged user from local storage?!
		
	}

	async function deleteStoryAsync(st?: dt.IStory ): Promise<void>{
		if (st === undefined) {return;}
		if (data.communicsUsr === undefined) {return;}
		const options = { 
			params: {
				userID: data.communicsUsr?.id,
				storyID: st.id
			}
		}
		await axios.get('st/deleteStory', options);
		await fetchUserStories(data.communicsUsr);
	}
	function  isIUser(o: any): o is dt.IUser {
 		 return "id" in o && "oauthID" in o
	}

	async function reload(): Promise<void> {
		const data = window.localStorage.getItem("loggedUser") ?? '';
		//const result = safeJsonParse(isIUser)(window.localStorage.getItem("loggedUser") ?? "");
		const user = JSON.parse(data) as dt.IUser;
		if (user.id !== undefined) {
			await fetchUserStories(user);
		}
	}

	

	return {
		currentUsr,
		logged,
	//	title,
		fetchUserStories,
		fetchAStory,
		updateCurrentStoryAsync,
		useTemplateAsync, 
		createFromTemplateAsync,
		fetchStoryTemplatesAsync,
		clearUser,
		clearLocalStorage,
	//	currentEditStory,
		currentResources,
		stories,
		storyTemplates,
		closedStories,
		deleteStoryAsync,
		//reload,
	}
});
