<template>
	<div class="my-moveable objContainer" ref="able">
		<!-- <div>{{ debugGeoms }}</div> -->
		<v-icon class="rotation-node" :icon="mdiRotateLeft" @mousedown="startRotation" />
		
	<!-- <div class="resize-node n" />
	<div class="resize-node ne" />
	<div class="resize-node e" /> -->
	<!-- <div class="resize-node s" />
	<div class="resize-node sw" />
	<div class="resize-node w" />
	<div class="resize-node nw" /> -->
		<img class="content" ref="image" :class="contentSelectionClass" 
			:src="useServices().api.getImageURI(mediaElement?.media?.mediaSourceID)"
			width="100"
			@mousedown="handleStartMove" />

		<div class="footer-toolbar" v-show="isSelected">
			<v-icon class="command-node" :icon="mdiFaceManProfile" @mouseup="changeFaceExpression"  />
			<v-icon class="command-node" :icon="mdiHumanNonBinary" @mouseup="changeGender"  />
			
			<v-icon class="resize-node" :icon="mdiMoveResizeVariant" @mousedown="startScale"></v-icon>
		</div>
	</div>
	<!-- <span class="debug">{{ output }}</span>
	<span class="debug">{{ output2 }}</span> -->
</template>

<script setup lang="ts">
import { mdiFaceManProfile  } from '@mdi/js';
import { mdiMoveResizeVariant } from '@mdi/js';
import { mdiHumanNonBinary } from '@mdi/js';
import { mdiRotateLeft } from '@mdi/js';

import { computed, nextTick, onMounted, ref , reactive} from 'vue';
import type { IMediaElement } from '@/model/sharedPojos';
import { useServices } from '@/composable/useServices';
import { usePanelStore } from '@/model/panelStore';

interface IProps {
	mediaElement: IMediaElement;
};

class TransformationMatrix {
	static readonly identity: TransformationMatrix = new TransformationMatrix();

	translateX: number;
	translateY: number;
	rotation: number;
	skewX: number;
	scaleX: number;
	scaleY: number;

	constructor() {
		this.translateX = 0;
		this.translateY  = 0;
		this.rotation = 0;
		this.scaleX = 1;
		this.scaleY = 1;
		this.skewX = 0;
	}

	addTranslation(dx: number, dy: number): void {
		this.translateX += dx;
		this.translateY += dy;
	}


	toMatrixString(): string {
		return "".concat(
			`translate(${this.translateX}px, ${this.translateY}px)`,
			`rotate(${this.rotation}deg)`,
			`scale(${this.scaleX}, ${this.scaleY})`);
	}
	static decomposeMatrixOf(el: Element): TransformationMatrix {
		const { transform } = getComputedStyle(el);
		const matrixString = transform;
		const mat = (matrixString.match(/-?\d+\.?\d+|\d+/g) || [1, 0, 0, 1, 0, 0])
			.map(x => Number(x));
		let scaleX = Math.sqrt(Math.pow(mat[0], 2) + Math.pow(mat[1], 2));
		if (scaleX !== undefined) {
			mat[0] /= scaleX;
			mat[1] /= scaleX;
		};
		let skewX = mat[0] * mat[2] + mat[1] * mat[3];
		if (skewX !== undefined) {
			mat[2] -= mat[0] * skewX;
			mat[3] -= mat[1] * skewX;
		}
		let scaleY = Math.sqrt(Math.pow(mat[2], 2) + Math.pow(mat[3], 2));
		if (scaleY !== undefined) {
			mat[2] /= scaleY;
			mat[3] /= scaleY;
			skewX /= scaleY;
		}
		if (mat[0] * mat[3] < mat[1] * mat[2]) {
			mat[0] *= -1;
			mat[1] *= -1;
			skewX *= -1;
			scaleX *= -1;
		}
		const retVal = new TransformationMatrix();
		const degrees = 180 / Math.PI;
		retVal.translateX = mat[4];
		retVal.translateY = mat[5];
		retVal.rotation = Math.atan2(mat[1], mat[0]) * degrees;
		retVal.skewX = Math.atan(skewX) * degrees;
		retVal.scaleX = scaleX;
		retVal.scaleY = scaleY;
		console.log(`matrixString? ${JSON.stringify(matrixString)} -> ${JSON.stringify(retVal)}`);
		return retVal;
	}


}

const props = defineProps<IProps>();
const panelStore = usePanelStore();
const services = useServices();
const transformReactive = reactive({ matrix: new TransformationMatrix() });

const selectionClass = computed(() => 
	panelStore.currentEditingElement.uuid === props.mediaElement.uuid? "selected" : "");
const contentSelectionClass = computed(() => 
	panelStore.currentEditingElement.uuid === props.mediaElement.uuid ? "contentSelected" : "");
// const width = computed(()=> zoomParameters.elWidth * zoomParameters.xZoomFactor);
// const height = computed(() => zoomParameters.elWidth * zoomParameters.yZoomFactor);
const resizedWidth = computed(() => "165px");
const resizedHeight = computed(() => "100px");
const flipTransform = computed(() => 
	 `scale(${ props.mediaElement.horzFlip ?? 1.0 }, `
		 .concat(`${ props.mediaElement.vertFlip ?? 1.0 }`));
// const debugGeoms = computed(() => {
// 	const s = `x ${zoomParameters.xZoomFactor}\n`
// 		.concat(`hflip ${props.mediaElement?.horzFlip}`);
// 	return s;
// });

const emits = defineEmits<{
	(e:"selectObj", media: IMediaElement): void
}>();

const isSelected = computed(() => {
	console.log(`is selected? ${panelStore.currentEditingElement.uuid === props.mediaElement.uuid}`)
	return panelStore.currentEditingElement.uuid === props.mediaElement.uuid
});


const able = ref({} as HTMLElement);
const image = ref({}as HTMLImageElement);
const transformableTarget = ref({} as HTMLElement);
const ableContainer = ref({} as HTMLElement);
const initTargetDims = {w:0.0, h:0.0};
const zoomParameters = {
	x: 0,
	y: 0, 
	cx: 0.0,
	cy: 0.0,
	elWidth: 0.0,
	elHeight: 0.0,
	xZoomFactor: 1.0,
	yZoomFactor: 1.0,
}


onMounted(async () =>{
	await nextTick();
	const rect = image.value.getBoundingClientRect();
	console.log(`rect: ${rect.width}`)
	ableContainer.value = able.value?.parentElement ?? {} as HTMLElement;
	able.value.style.transform = transformReactive.matrix.toMatrixString();
	transformReactive.matrix.addTranslation(props.mediaElement.xPosition, props.mediaElement.yPosition);
	transformReactive.matrix.rotation = props.mediaElement.rotationAngle;
	zoomParameters.elWidth = rect.width;
	zoomParameters.elHeight = rect.height;

	let xZoom = props.mediaElement.xZoomFactor ?? 1;
	let yZoom = props.mediaElement.yZoomFactor ?? 1;
	zoomParameters.xZoomFactor = xZoom == 0 ?  1: xZoom;
	zoomParameters.yZoomFactor = yZoom == 0 ? 1: yZoom;
	able.value.style.transform = transformReactive.matrix.toMatrixString();
	image.value.style.transform = ``;
	console.log(`tmat: ${transformReactive.matrix.toMatrixString()}`)
	console.log(`zparameters: ${JSON.stringify(zoomParameters)}`)
	image.value.width = rect.width * zoomParameters.xZoomFactor;
	image.value.height = rect.height * zoomParameters.yZoomFactor;
});

function handleStartMove(ev: MouseEvent): void {
	ev.preventDefault();
	const target = (ev.currentTarget as HTMLElement).parentElement as HTMLElement ;
	//const rect = (target as Element).getBoundingClientRect();
	zoomParameters.x = ev.clientX as number;
	zoomParameters.y = ev.clientY as number;
	// = TransformationMatrix.decomposeMatrixOf(target)
	transformableTarget.value = target;
	console.log(`trasformableTarget: ${transformableTarget.value.className},  [${able.value.className}]`)
	console.log(`handleStartMove able == transformable ${transformableTarget.value === able.value}`)
	ableContainer.value.addEventListener("mousemove", handleMove);
	ableContainer.value.addEventListener("mouseup", handleEndMove);
	window.addEventListener("mouseup", handleEndMove);
	panelStore.setEditingContent(props.mediaElement);
	emits("selectObj", props.mediaElement);
}
function handleMove(ev: MouseEvent){
	ev.preventDefault();
	if (transformableTarget.value === undefined ||
		transformableTarget.value === {} as HTMLElement) { return; }
	transformReactive.matrix.addTranslation(
		ev.clientX - zoomParameters.x,
		ev.clientY - zoomParameters.y)
//	output.value = `traslate of x: ${(ev.clientX as number - zoomParameters.x)}`
	transformableTarget.value.style.transform = transformReactive.matrix.toMatrixString();
//	output.value = `rectangle: ${JSON.stringify(transformableTarget.value.getBoundingClientRect())}`
	zoomParameters.x = ev.clientX as number;
	zoomParameters.y = ev.clientY as number;
//	console.log(`after transform: ${JSON.stringify(matrix)}`);
}

function handleEndMove(ev: MouseEvent): void {
	ev.preventDefault();
	ev.stopImmediatePropagation();
	panelStore.updateGeometry(
		{x: transformReactive.matrix.translateX,
		y: transformReactive.matrix.translateY});
	detachHandlers(handleMove, handleEndMove);
}


function startRotation(ev: MouseEvent):void {
	ev.preventDefault();
	const target = (ev.currentTarget as HTMLElement).parentElement as HTMLElement; //able
	if (target === undefined) { return; }
	const rect = (target).getBoundingClientRect();
	zoomParameters.cx = rect.left + rect.width / 2;
	zoomParameters.cy = rect.top + rect.height / 2;
	//matrix = TransformationMatrix.decomposeMatrixOf(target);
	transformableTarget.value = target;
	ableContainer.value.addEventListener("mousemove", handleRotation);
	ableContainer.value.addEventListener("mouseup", stopRotation);
	window.addEventListener("mouseup", stopRotation);
}
function handleRotation(ev: MouseEvent) {
	ev.preventDefault();
	const cursorPoint = { x: ev.clientX, y: ev.clientY }
	const newAngle = (180 / Math.PI) * Math.atan2(cursorPoint.x - zoomParameters.cx, - (cursorPoint.y - zoomParameters.cy));
	transformReactive.matrix.rotation = newAngle;
	//contentProxy.rotationAngle = newAngle;

	//output2.value = "de=" + newAngle;
	transformableTarget.value.style.transform = transformReactive.matrix.toMatrixString();
}
function stopRotation(ev: MouseEvent): void {
	panelStore.updateGeometry({
		rot: transformReactive.matrix.rotation
	});
	detachHandlers(handleRotation, stopRotation);
}
function startScale(ev: MouseEvent) {
	ev.preventDefault();
	console.log("start rescaling");
	if (image.value ===  undefined) {return;}
	const rect = image.value.getBoundingClientRect();

	zoomParameters.cx = rect.left + rect.width / 2;
	zoomParameters.cy = rect.top + rect.height / 2;
	zoomParameters.x = ev.clientX;
	zoomParameters.y = ev.clientY;
	zoomParameters.elWidth = rect.width;
	zoomParameters.elHeight = rect.height;

	ableContainer.value.addEventListener("mousemove", handleScale);
	ableContainer.value.addEventListener("mouseup", stopScale);
	window.addEventListener("mouseup", stopScale);
}
function handleScale(ev: MouseEvent) {
	ev.preventDefault();
	let xZoom = 1 + (ev.clientX - zoomParameters.x) / zoomParameters.elWidth;
	let yZoom =  1 +  (ev.clientY - zoomParameters.y) / zoomParameters.elHeight;
	console.log(`zoomX inside able component ${xZoom}`);

	// yZoom = Math.max(yZoom, 0.15);
	// yZoom = Math.min(yZoom, 1.75);
	// xZoom = Math.max(xZoom, 0.15);
	// xZoom = Math.min(xZoom, 1.75);
	/*image.value.style.width = `${zoomParameters.elWidth * xZoom}px`;
	image.value.style.height = `${zoomParameters.elHeight * yZoom}px`;*/

	//cap zoom 
	xZoom = xZoom < 0.30 ? 0.31: xZoom;
	console.log(`capped x-zoom ${xZoom}`);

	image.value.width = zoomParameters.elWidth * xZoom;
	image.value.height = zoomParameters.elHeight * yZoom;

	//console.log(`scaling:  ${xZoom}, ${yZoom}`);
	zoomParameters.xZoomFactor = xZoom;
	zoomParameters.yZoomFactor = yZoom;
	// if (w < initTargetDims.w * 0.25 
	// 	|| w > initTargetDims.w * 1.5
	// 	|| h < initTargetDims.h * 0.25 
	// 	|| h > initTargetDims.h * 1.5) {return; }
	// //output2.value = `w ${w}`;
	// console.log(`scaling:  ${w}px`);
	// //transformableTarget.value.style.width = `${w}px`;
	// //target.style.height = `${zoom.h}px`;
	// //output.value = `rect ${JSON.stringify(zoomParameters)}`;
}
async function stopScale(ev: MouseEvent):Promise<void> {
	console.log("stop rescaling");
	await panelStore.updateGeometry({
		zoomX: zoomParameters.xZoomFactor,
		zoomY: zoomParameters.yZoomFactor,
	});
	detachHandlers(handleScale, stopScale);	
	await nextTick();
}

async function changeFaceExpression(ev: MouseEvent): Promise<void>{
	console.log(`change face expression ${props.mediaElement.media.resourcePath}`);
	await panelStore.nextFaceExpression(props.mediaElement.media.resourcePath);
	
	await nextTick();
}

async function changeGender(ev: MouseEvent): Promise<void>{
	await panelStore.changeStyle(props.mediaElement.media.resourcePath);
	await nextTick();
}

function detachHandlers(mouseMoveHandler: any, mouseUpHandler: any){
	transformableTarget.value = {} as HTMLElement;
	ableContainer.value.removeEventListener("mousemove", mouseMoveHandler);
	ableContainer.value.removeEventListener("mouseup", mouseUpHandler);
	window.removeEventListener("mouseup", mouseUpHandler);
}
</script>


<style scoped lang="scss">
.content {
	//border-style: solid;
	border-color: rgb(81, 51, 142);
	height: auto;
	// max-width: 120%;
	// max-height: 120%;
	// min-width: 20%;
	// min-height: 20%;
	transform: v-bind('flipTransform');
	resize:both;
}
.contentSelected {
	//border: 2px solid rgb(217, 246, 71);
	border: 1px solid $selection-highlight-1;
}
.my-moveable {
	position: absolute;
	transform: v-bind('transformReactive.matrix.toMatrixString()');
}
.objContainer {
	display: flex;
	flex-direction: column;
	font-size:1.2rem;
	color:$selection-highlight-1;

}
.footer-toolbar {
	display: flex;
	flex-direction: row;
	justify-content:flex-start;
	align-items: stretch;
}
.selected {
	display : block !important;
	// border-color: 'rgb(158, 190, 169) !important'
}

.rotation-node {
	align-self: center;
	// display: none;
	// position: absolute;
	// width: 20px !important;
	// height: 20px !important;
	// margin-top: -40px !important;
	// margin-left: 50% !important;
	// border: 2px solid ;
	// border: 2px solid rgb(217, 246, 71);
	// background-color: rgba(217,
	// 			246,
	// 			71, 0.5);
	// border-radius: 50%;
	
	//transform: translate(50%, -50%);
	//box-sizing: border-box;
	cursor: url(../assets/arrows-rotate-solid.svg),
		auto;
	z-index: 10;
}

.resize-node {
	//align-self: flex-end;
	margin-left: auto;
	z-index: 11;
	cursor: se-resize;
	
}

.n {
	margin-top: 0px;
	margin-left: 50%;
	border: 2px solid rgb(158, 190, 169);
}

.e {
	margin-top: 50%;
	margin-left: 100%;
	border: 2px solid rgb(158, 190, 169);
}

.s {
	margin-top: 100%;
	margin-left: 50%;
	border: 2px solid rgb(158, 190, 169);
}

.w {
	margin-top: 50%;
	margin-left: 0px;
	border: 2px solid;
}

.ne {
	margin-top: 0px;
	margin-left: 100%;
}

.nw {
	margin-top: 0px;
	margin-left: 0px;
}

.se {
	margin-top: 100%;
	margin-left: 100%;
}

.sw {
	margin-top: 100%;
	margin-left: 0px;
}

.debug {
	width: 100px;
	height: auto;
	display: block;
	z-index: 30;
	top: 10px;
	left:10px

}
.command-node{}
</style>