// /composables/useServices.ts
import AuthService from '@/services/authService';
import ApiService from '@/services/apiService';
import { useAuth } from '@/model/authStore';
export const useServices = () => {
	const authStore = useAuth();
	const auth: AuthService = new AuthService();
	const api: ApiService = new ApiService(authStore.accessToken);

	return {
		auth,
		api
	}
}