import { defineStore } from 'pinia';
import {reactive} from 'vue';
import { computed } from '@vue/reactivity';
import axios from 'axios';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import type * as dt from './sharedPojos';

import { useCommunicsStore } from './communicsStore';
import { usePanelStore } from './panelStore';
import { useAuth } from './authStore';

dayjs.extend(utc);

interface IStoryEditor {
	currentEditingPanel:dt.IPanel;
	panelIdx: number;
	storyRef: dt.IStory | undefined;
}
export const useStoryEditorStore = defineStore("storyEditor", () =>{
	const data:IStoryEditor = reactive<IStoryEditor>({} as IStoryEditor);
	const getPanelAt = (idx:number):dt.IPanel => {
		const story = data.storyRef;
		return (story?.storyboard[idx] ?? {} as dt.IPanel);
	};
	const storyboard = computed(() => data.storyRef?.storyboard ?? [] as Array<dt.IPanel>);
	const selectedPanelIdx = computed(()=> data.panelIdx);
	const title = computed({
		get() {return data.storyRef?.title ?? " ";},
 		set (val:string){
			if (data.storyRef !== undefined){ data.storyRef.title = val;}
		}
	});
	const storyRef = computed({
		get() {return data.storyRef ?? {} as dt.IStory;},
 		set (val:dt.IStory){data.storyRef = val;}
	});
	const canModify = computed(() => data.storyRef?.environment.closedAt === undefined);
	const storyboardRef = computed(() => data.storyRef?.storyboard);

	async function updatePanelSelectionAsync(idx: number):Promise<void>{
		try {
			console.log("da cambiare come viene selezionato il pannello dentro storyEditor")
			data.currentEditingPanel = storyboard?.value[idx];
			data.panelIdx = data.currentEditingPanel !== undefined ?  idx: -1;
			const s = usePanelStore();
			s.changePanelSelection(data.currentEditingPanel);
		} catch (error: unknown) {
			data.panelIdx = -1;
			console.log(error);
		}
	};

	function loadState() {
		console.log(`this method should be removed`);
		//author.value = currentUsr ?? {} as IUser;
	}

	async function addPanelAsync(mediaSource:dt.IMediaSource): Promise<void>{
		let addIdx = storyboard.value.length;
		if (data.panelIdx !== undefined) {
			addIdx = data.panelIdx + 1;
		}
		const {loggedUsr} = useAuth();
		const newPanel:dt.IPanel = {
			id: "",
			title: "",
			uuid: self.crypto.randomUUID(),
			owner: loggedUsr,
			background: {
				id: "",
				mediaSourceUri: mediaSource.resourceUri,
				mediaSourceID: mediaSource.id,
				resourcePath: mediaSource.path
			},
			balloons: [],
			characters: [],
			sceneObjects: []
		} as dt.IPanel;
		storyboard.value.splice(addIdx, 0, newPanel);
		await updatePanelSelectionAsync(addIdx);
		await updateStoryAsync();
	}

	async function deletePanelAsync(idx?: number): Promise<void>{
		idx = idx ?? data.panelIdx;
		storyboard.value.splice(idx, 1);
		idx = Math.min(idx, storyboard.value.length -1);
		await updatePanelSelectionAsync(idx);
		const params = { updatingStory: data.storyRef }
		await axios.post("/editor/story/update", params);
	}
	async function clonePanelAsync(idx?: number): Promise<void> {
		if (data.panelIdx === undefined
			|| data.currentEditingPanel === undefined) {return;}
		idx = idx ?? data.panelIdx;
		const cloned = _.cloneDeep(data.currentEditingPanel);
		cloned.id = "";  //remove it to leave backend creates its own
		cloned.owner = useCommunicsStore().currentUsr;
		cloned.uuid = self.crypto.randomUUID(),
		cloned.characters ??= [] as dt.IMediaElement[];
		cloned.sceneObjects ??= [] as dt.IMediaElement[];
		cloned.balloons ??= [] as dt.IBalloon[];

		await Promise.all(
			cloned.sceneObjects.map(
				async (obj) => obj.uuid = self.crypto.randomUUID()));
		await Promise.all(
			cloned.characters.map(
				async (obj) => obj.uuid = self.crypto.randomUUID()));
		await Promise.all(
			cloned.balloons.map(
				async (obj) => obj.uuid = self.crypto.randomUUID()));
		
		storyboard.value.splice(idx + 1, 0, cloned);
		await updatePanelSelectionAsync(idx +1);
		await updateStoryAsync();
	}
	async function movePanelAsync(from: number, to: number): Promise<void>{
		if (from === -1 || to === -1) {return;}
		const p = storyboard.value.splice(from, 1)[0];
		console.log(`sliced  ${p} `);
		if (p !== undefined) {
			storyboard.value.splice(to, 0, p);
		}
		await updatePanelSelectionAsync(to);
		await updateStoryAsync();
	}

	async function addCharacterAsync(mediaSource:dt.IMediaSource): Promise<void>{
		const {loggedUsr} = useAuth();
		const character:dt.IMediaElement = {
			media:  {
				id: "",
				mediaSourceUri: mediaSource.resourceUri,
				mediaSourceID: mediaSource.id,
				resourcePath: mediaSource.path
			},
			uuid: self.crypto.randomUUID(),
			ownerID: loggedUsr?.id || "" ,
			rotationAngle: 0,
			xPosition:Math.random() * 100, 			///just for demo -> TO DELETE
			yPosition:Math.random() * 100,			///just for demo -> TO DELETE
			xZoomFactor: 0.0,
			yZoomFactor: 0.0,
			horzFlip: 1.0,
			vertFlip: 1.0,
			createdAt: dayjs.utc().toDate()
		};
		data.currentEditingPanel?.characters?.push(character);
		await updateStoryAsync();
	}
	async function addObjectAsync(mediaSource:dt.IMediaSource): Promise<void>{
		const sceneObj:dt.IMediaElement = {
			media:  {
				id: "",
				mediaSourceUri: mediaSource.resourceUri,
				mediaSourceID: mediaSource.id,
				resourcePath: mediaSource.path
			},
			uuid: self.crypto.randomUUID(),
			ownerID: useAuth().loggedUsr?.id || "",
			rotationAngle: 0.0,
			xPosition:Math.random() * 700, 			///just for demo -> TO DELETE
			yPosition:Math.random() * 520,			///just for demo -> TO DELETE
			xZoomFactor: 1,
			yZoomFactor: 1,
			createdAt: dayjs.utc().toDate(),
		};
		data.currentEditingPanel?.sceneObjects?.push(sceneObj);
		console.log(`adding obj: ${JSON.stringify(sceneObj)}`);
		await updateStoryAsync();
	}

	async function addEmojiAsync(mediaSource:dt.IMediaSource): Promise<void>{
		const obj:dt.IMediaElement = {
			media:  {
				id: "",
				mediaSourceUri: mediaSource.resourceUri,
				mediaSourceID: mediaSource.id,
				resourcePath: mediaSource.path
			},
			uuid: self.crypto.randomUUID(),
			ownerID: useAuth().loggedUsr?.id || "",
			rotationAngle:0,
			xPosition:Math.random() * 700, 			///just for demo -> TO DELETE
			yPosition:Math.random() * 520,			///just for demo -> TO DELETE
			xZoomFactor: 1.0,
			yZoomFactor: 1.0,
			createdAt: dayjs.utc().toDate(),
		};
		data.currentEditingPanel?.sceneObjects?.push(obj);
		await updateStoryAsync();
	}

	//async function addText(t: string ): Promise<void>{throw Error("add text not impl");}

	async function addCustomTextAsync(text: string): Promise<void>{
		const currentAuthor = useAuth().loggedUsr;
		if (data.currentEditingPanel.balloons === undefined) {
			console.error("no panel selected")
			return;
		}
		const customText:dt.IBalloon = {
			uuid: self.crypto.randomUUID(),
			description: `custom text added by ${currentAuthor?.displayName || "undefined!!!"}`,
			ownerID: currentAuthor?.id || "",
			text: text,
			style: "speech",
			tailOrientation: "left",
			rotationAngle: 0,
			xPosition:Math.random() * 700, 			///just for demo -> TO DELETE
			yPosition:Math.random() * 520,			///just for demo -> TO DELETE
			xZoomFactor: 1.0,
			yZoomFactor: 1.0,
			createdAt: dayjs.utc().toDate()
		};
		data.currentEditingPanel?.balloons?.push(customText);
		await updateStoryAsync();
	}
	async function updateStoryAsync(): Promise<void>{
		const params = { updatingStory: data.storyRef }
		await axios.post("/editor/story/update", params);
	}
	async function endStory():Promise<void>{
		if (data.storyRef !== undefined) {
			data.storyRef.environment.closedAt = new Date();
			useCommunicsStore().clearLocalStorage(data.storyRef.id);
			await updateStoryAsync();

		}
		
	}

	
	async function processAddContent(action: any): Promise<void>{
		console.log(`adding content`);
		//here is the business logic for adding content to panel
		if (action.what !== 'add-content') {throw new Error("wrong action");}
		if (action.command === 'background') {
			await addPanelAsync(action?.media);
		}
		else if (action.command === 'character') {
			await addCharacterAsync(action?.media);
		}
		else if (action.command === 'object') {
			await addObjectAsync(action?.media);
		}
		else if (action.command === 'emoji') {
			await addEmojiAsync(action?.media);
		}
		else {
			throw new Error('wrong parameter');
		}
	}


	function clear(){
		data.currentEditingPanel = {} as dt.IPanel;
		data.panelIdx = -1;
		data.storyRef = undefined;
	}
	return {
		data,
		selectedPanelIdx,
		canModify,
		getPanelAt,
		title, 
		loadState,
		storyRef,
		storyboardRef,
		clear,
		updatePanelSelectionAsync,
		addPanelAsync,
		clonePanelAsync,
		deletePanelAsync,
		movePanelAsync,
		addCharacterAsync,
		addObjectAsync,
		addCustomTextAsync,
		addEmojiAsync,
		endStory,
		updateStoryAsync,
		processAddContent
	}
});