<template>
	<div>
		<span class="text-h6 mt-6">Available steps</span>
		<v-list class="actionRules" lines="two">
			<v-list-item v-for="(actionRule, idx) in availableActions" 
				:value="actionRule"
				:key="idx"
				:title="actionRule.name"
				:subtitle="actionRule.description">
				<template v-slot:prepend>
					<v-icon :icon="mdiCheckboxBlankCircleOutline"  small v-if="!actionRule.completed"></v-icon>
					<v-icon :icon="mdiCheckCircleOutline"  small v-else ></v-icon>
				</template>
					<v-btn class="actionRuleButton" v-if="activeAction === actionRule" @click="handleCompleted(actionRule)">Complete</v-btn>
				
			</v-list-item>
		</v-list>
		
	</div>
	<!--<v-stepper >
		
					<div>
						<v-icon :icon="mdiCheckboxBlankCircleOutline"  small v-if="!actionRule.completed"></v-icon>
						<v-icon :icon="mdiCheckCircleOutline"  small v-else ></v-icon>
						<span>{{ actionRule.name }}</span>
					</div>
					
					<block>{{ actionRule.description }}</block>
					<v-btn v-if="activeAction === actionRule" @click="actionRule.completed = true">Complete</v-btn>
		<v-stepper-header >
			<div v-for="(actionRule, idx) in availableActions" :key="idx">
				<v-stepper-item :title="actionRule.name">
				</v-stepper-item>
			</div>
			
		</v-stepper-header>
	</v-stepper> --> 
<!-- 
	<ul>
		<li v-for="(actionRule, idx) in availableActions" :key="idx">{{ actionRule.name }}</li>


	</ul> -->
</template>
<style lang="scss" scoped>
.actionRules {
	list-style-type:disc;
	margin: 0;
	padding: 0;
}
.actionRuleButton {
	margin: 16px 2px 2px 2px;
	padding: 4px;
}


</style>


<script setup lang="ts">
import { mdiCheckboxBlankCircleOutline } from '@mdi/js';
import { mdiCheckCircleOutline } from '@mdi/js';
import { ref } from "@vue/reactivity";
import { storeToRefs } from "pinia";
import {useStoryEditorStore} from "../model/storyEditorStore";
import { computed } from "vue";
import type { IActionRule } from '@/model/sharedPojos';

const storyStore = useStoryEditorStore();
const  availableActions = computed(()=>{
	return storyStore.storyRef.actionRules;
});

//sequential so activeAction is the first one not yet completed;
const activeAction = computed(()=>{
	const activeIdx = storyStore.storyRef.actionRules.findIndex(el => !el.completed);
	if (activeIdx !== -1) {return storyStore.storyRef.actionRules[activeIdx]}
	return null;
});
function handleCompleted(action:IActionRule) :void{
	action.completed = true;
	console.log(`active action is now: ${activeAction.value?.name}`);
}
</script>