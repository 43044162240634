<template>
	<v-card>
		<v-tabs v-model="tab" background-color="light-green accent-5" dark center-active>
			<v-tab v-for="item in tabs" :key="item.tab" class="tab-item">
				{{ item.tab }}
			</v-tab>
		</v-tabs>
		<v-window v-model="tab">
			<v-window-item v-for="item in tabs" :key="item.tab">
				<template v-if="item.tab === 'Custom texts'">
					<v-row class="customText">
						<v-col cols="8" md="8">
							<v-textarea v-model="customText" label="type your text" type="text" rows="4" row-height="40"
								counter maxlength="200" no-resize clearable variant="outlined"
								hint="this hint can be suggested by external service!!"
								@click:clear="clearCustomText"></v-textarea>

						</v-col>
						<v-col class="addButton">
							<v-btn v-visibility="customText.length > 0" variant="flat" :icon="mdiTextBoxPlusOutline"
								@click="addCustomText"></v-btn>
						</v-col>
					</v-row>
				</template>
				<horizontal-list v-else :listItems="(item.model as IMediaSource[])">
					<template v-slot="slotProps">
						<!-- <lazy-image class="thumb" :mediaUri="getImageUri(slotProps.content)"
								@click="item.clickAction(slotProps.content)">
						</lazy-image>  -->
						<!-- class="thumb" :src="getImageUri(slotProps.content)" @click="item.clickAction(slotProps.content)"  -->
						<img 
							class="thumb"  
							draggable="true"
							:src="getImageUri(slotProps.content)"
							@click="item.clickAction(slotProps.content)" 
							@dragstart="(ev) => handleStartDrag(ev, item, slotProps.content,)"/>
					</template>
				</horizontal-list>
			</v-window-item>
		</v-window>
	</v-card>
</template>




<script setup lang="ts">
import { mdiTextBoxPlusOutline } from '@mdi/js';
import { ref } from "@vue/reactivity";
import { storeToRefs } from "pinia";
import {useServices} from '@/composable/useServices';

import LazyImage from "./LazyImageComponent.vue";
import HorizontalList from "./HorizontalList.vue";

import type { IMediaSource } from "@/model/sharedPojos";
import type visibility from '@/directives/visibility';
import { useCommunicsStore} from "@/model/communicsStore";
import { useStoryEditorStore } from '@/model/storyEditorStore';



const communicsStore = useCommunicsStore();
const { currentResources } = storeToRefs(communicsStore);
const storyStore  = useStoryEditorStore();
const services = useServices();


const tab = ref(0);
const tabs = [
	{
		tab: 'Backgrounds',
		model: currentResources.value.backgrounds,
		clickAction: async (f: IMediaSource) => await storyStore.addPanelAsync(f),
		dragAction: (f: IMediaSource): any => {
			return {
				what: "add-content",
				command: "background", 
				media: f,
			}
		}
	}, {
		tab: 'Characters',
		model: currentResources.value.characters,
		clickAction: async (f: IMediaSource) => await storyStore.addCharacterAsync(f),
		dragAction: (f: IMediaSource): any => {
			return {
				what: "add-content",
				command: "character",
				media: f,
			}
		}
	}, {
		tab: 'Objects',
		model: currentResources.value.sceneObjects,
		clickAction: async (f: IMediaSource) => await storyStore.addObjectAsync(f),
		dragAction: (f: IMediaSource): any => {
			return {
				what: "add-content",
				command: "object",
				media: f,
			}
		}
	}, {
		tab: 'Emoji',
		model: currentResources.value.emojis,
		clickAction: async (f: IMediaSource) => await storyStore.addEmojiAsync(f),
		dragAction: (f: IMediaSource): any => {
			return {
				what: "add-content",
				command: "emoji",
				media: f,
			}
		}
	}, {
		tab: 'Custom texts',
		model: 'string',
		clickAction: async (t: string) => await storyStore.addCustomTextAsync(t)
	}
];
const customText = ref("");

function clearCustomText(ev: Event){
	console.log(`clear ${customText.value}`);
	customText.value = "";
}
async function addCustomText(ev: Event): Promise<void> {
	console.log("add custom text");
	await storyStore.addCustomTextAsync(customText.value);
	customText.value = "";
}
function addCustomText2(ev: Event) {
	console.log(`adding text 2 ${customText.value}`);
}

function getImageUri(media: IMediaSource):string {
	return useServices().api.getImageURI(media.id);
}
async function getImage(media: IMediaSource):Promise<string>{
	return await services.api.getImage(media.id);
}
function handleStartDrag(ev: DragEvent, tabItem: any,  md: IMediaSource){
	console.log(`start drag on lib element with id ${md.id}`);
	//only for graphic efx
	const eventTarget = (ev.target ?? {}) as HTMLElement;
	//const  ghost = eventTarget.cloneNode() as HTMLElement;
	//newGhost.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'"
	console.log(`undefined: ${ev.dataTransfer === undefined}`);
	const canvas = document.createElement('canvas') ;
	const ctx = canvas.getContext("2d") ?? {} as CanvasRenderingContext2D ; 
	ctx.fillStyle = `red`;
	ctx.fillRect(0,0, 100, 30,)
	const dragAction =  tabItem?.dragAction(md) ?? {};
	//ev.dataTransfer?.setDragImage(canvas, 0, 0);
	ev.dataTransfer?.setData(
		dragAction.what,
		JSON.stringify(dragAction));
}
</script>

<style scoped lang="scss">
.customText {
	padding-top:3px;
	padding-left: 3px;
	margin-top: 3px;
	margin-left: 3px;
}
.addButton {
	margin-top: 3px;
	margin-left: -6px;
}
.thumb {
	//max-height: 170px;
	max-width: 10vw;
	max-height: 10vh;
	// width: 5vw;
	// min-height: 5vh;
	//background-color: black;
	//min-height: 170px;
	/*Set any dimensions you like*/
	//width: 100%;
	//pointer-events: none;
}
// .thumb img {
// 	//width: 100%;
// 	//max-width: 10wv;
// 	max-width: 100px;
// 	height: auto;
// 	//max-height: 170px;
// 	max-height: 0.2em;
// 	background-size:auto;
// 	background-repeat: no-repeat;
// 	background-position: center;
// }
.tab-item {
	margin-right: 20px;


}
.debug {
	visibility: hidden;
}
</style>