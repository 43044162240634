import { Log, UserManager, User} from "oidc-client-ts";

export default class AuthService {
	userManager: UserManager;
	constructor() {
		this.userManager = new UserManager({
    		// filterProtocolClaims: true
			authority: process.env.VITE_AUTHORITY_URL ?? "not set",
			client_id: process.env.VITE_CLIENT_ID ?? "--",
			redirect_uri: window.location.origin + "/callback",
			popup_redirect_uri: window.location.origin + "/callback",
			popup_post_logout_redirect_uri: window.location.origin + "/sample-popup-signout.html",
			silent_redirect_uri: window.location.origin + "silent-renew.html",
			post_logout_redirect_uri: window.location.origin+ "/callback-logout",
			response_type: "code",
			scope: "openid offline_access profile email"
			//response_mode: "fragment",
			// filterProtocolClaims: true,
		});

		this.handleUserLoaded = this.handleUserLoaded.bind(this);
		// this.userManager.events.addAccessTokenExpiring(function () {console.log("token expiring");});
		// this.userManager.events.addAccessTokenExpired(function () {console.log("token expired");});
		// this.userManager.events.addSilentRenewError(function(e) {console.log("silent renew error", e.message);});
		// this.userManager.events.addUserSignedIn(function() {console.log("user sign in");});
		this.userManager.events.addUserLoaded(this.handleUserLoaded);
		this.userManager.events.addUserUnloaded(function() {console.log("user unloaded");});
		Log.setLogger(console);
    	Log.setLevel(Log.INFO);
	}

	async handleUserLoaded(): Promise<void> {
    	console.log("handling user loaded");
    	const u = await this.userManager.getUser();
		console.log(`usr profile sub ${JSON.stringify(u!.profile)}`);
	}

	async signIn():Promise<void>{
		try {
			console.log(`start requesting sso `);
			 const data = {
            	//hash: location.hash.length > 0 ? location.hash : '#',
        	};
			await this.userManager.signinRedirect({state:data});
		} catch (e) {
			console.error(`error in signin: ${e}`);
		}
	}

	async signInCallback():Promise<void>  {
		await this.userManager.signinCallback(window.location.href);
		
	}

	async renewToken(): Promise<void>{
		await this.userManager.signinSilentCallback();
	}

	async signOut(): Promise<void> {
		await this.userManager.removeUser();
		await this.userManager.signoutRedirect();
	}

	async getUser(): Promise<User | null>{
		return await this.userManager.getUser();
	}


}

