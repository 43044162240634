<template>
	<v-container class="ma-8" v-if="authStore.isLoggedIn">
		
		
		
		<!-- <v-dialog v-model="loading" fullscreen full-width>
		  <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
		    <v-layout justify-center align-center>
		      <v-progress-circular
		        indeterminate
		        color="primary">
		      </v-progress-circular>
		    </v-layout>
		  </v-container>
		</v-dialog> -->
		<div>
			<p class="text-h3">Welcome, {{ store.currentUsr.displayName }}</p>
		</div>
		<v-divider color="#ff000000" class="mt-6"></v-divider>
		<v-dialog v-model="loading" :scrim="false" persistent width="auto">
	    	<v-card color="primary">
				<v-card-text>
					Loading story
					<v-progress-circular
						indeterminate
						color="primary"
						class="mb-0">
					</v-progress-circular>
				</v-card-text>
			</v-card>	
		</v-dialog>
		<p class="text-h6 mt-6">Available Templates:</p>
		<v-text-field dense clearable label="Search" hint="look for templates with..." 
			v-model="templateFilter"
			:append-inner-icon="mdiMagnify"
			:loading="loadingTemplates"
			@click:appendInner="handleFilter"
			@click:clear="handleClearFilter"></v-text-field>
		<StoryItem class="template stories" v-for="(template) in store.storyTemplates" :key="template.id" :template="template" @editFromTemplate="handleEditFromTemplate"></StoryItem>
		<p class="text-h6 mt-6">Stories:</p>
		<StoryItem class="stories" v-for="(story) in store.stories" :key="story.id" :story="story" @editStory="handleEditStory"></StoryItem>
		<p class="text-h6 mt-6">Finished stories</p>
		<StoryItem closed class="stories" v-for="(story) in store.closedStories" :key="story.id" :story="story" @editStory="handleEditStory"></StoryItem>
		
	</v-container>

	<v-container v-else class="login-req">
		<p class="text-h2">Login required</p>
		<p class="text-subtitle1 ">You must be logged in to access this section.</p>
		<v-icon large :icon="mdiLogin" color="primary" class="text-h1 mt-30" />
		<v-btn width="10rem" @click="handleLogin">Login</v-btn>
	</v-container>

     
</template>

<script setup lang="ts">
import { mdiLogin, mdiMagnify} from '@mdi/js'
//import { mdiAccountMultipleOutline } from '@mdi/js'
import { useRouter } from 'vue-router';
import {useServices} from '@/composable/useServices'
import StoryItem from "./StoryItem.vue"

//  import type { IappState } from "../model/coreAppData";
import type { IStory, IStoryTemplate } from '@/model/sharedPojos';
import { useCommunicsStore } from '@/model/communicsStore';
import { useAuth } from '@/model/authStore';
import {  ref } from 'vue';


const store = useCommunicsStore();
const authStore = useAuth();
const services = useServices();
const router = useRouter();

const templateFilter = ref('');
const loadingTemplates = ref(false);
const loading = ref(false);
async function handleEditStory(payload: IStory): Promise<void> {
	try {
		loading.value = true;
		await store.updateCurrentStoryAsync(payload);
		router.push({
			path: "/editStory"
		});
	}
	catch (error: unknown) {
		console.log(error);
	}
	loading.value = false;
}
async function handleEditFromTemplate(payload: IStoryTemplate): Promise<void> {
	console.log(`you requesting to edit ${payload.title}`);
	try {
	 	loading.value = true;
		const params = {templateID: payload.id, userID:store.currentUsr.id };
	 	await store.useTemplateAsync(params);
	 	router.push({
	 		path: "/editStory"
		});
	}
	catch (error: unknown) {
		console.log(error);
	}
	loading.value = false;
}
async function handleLogin(): Promise<void> {
	try {
		await services.auth.signIn();
	}
	catch (error: unknown) {
		console.log(error);
	}
}
async function handleFilter(){
	const filter = templateFilter.value.split(',');
	loadingTemplates.value = true;
	await store.fetchStoryTemplatesAsync(filter);
	loadingTemplates.value = false;
}
async function handleClearFilter() {
	templateFilter.value = "";
	await store.fetchStoryTemplatesAsync();
}

</script>

<style scoped lang="scss">
.login-req {
	padding-top: 3rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	row-gap: 10px;
	height: 30rem;
	max-height: 600rem;
	max-width: 800rem;
	/* background-color: green; */
}

.border {
	border-width: 1px;
	border-color: black;
	background-color: aqua;
}

.h1 {
	padding-top: 3rem;
	padding-bottom: 5rem;
	font-size: xx-large;
}
.h5 {
	padding-top: 1rem;
	padding-bottom: 1rem;

}

.descr {
	flex: 1;
	margin-left: 1rem;
}
.stories {
	display: inline-flex;
	margin: 0.5rem;
	padding: 1rem;
}
.template{
	color: $text-base-color-2;	
}
.closed{
	color: $text-base-color-4;
}

.popup-visible {
    position: absolute;
    z-index: 10;
    visibility: visible;
}

.popup-hidden {
    position: absolute;
    z-index: 10;
    visibility: hidden;
}
</style>