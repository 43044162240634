<template>
	<v-container>
		<div> go to external website </div>
		<v-btn class="ma-2 ml-5" @click="handleGoTo">
			<v-icon :icon="mdiAlphaXCircle" class="mr-1"></v-icon>
			<span>go on google</span>

		</v-btn>
	</v-container>
</template>


<script setup lang="ts">
import { mdiAlphaXCircle } from '@mdi/js';
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";


function handleGoTo(ev: MouseEvent): void{
	window.location.href = "https://google.com/";

}

</script>
