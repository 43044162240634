
import { 
	createRouter, 
	createWebHistory, 
	RouterView } from 'vue-router'
import StoryEditorView from  "@/view/StoryEditorView.vue";
import ManagerView from "../components/ManagerView.vue";
import HomeView from "../components/HomeView.vue";
import AuthCallback from '../components/AuthCallback.vue';
import SilentRenew from '../components/SilentRenew.vue';
import DirectStoryEditorViewVue from '../view/DirectStoryEditorView.vue';
import ErrorPageView from '../view/ErrorPageView.vue';
import EndStoryView from '../view/EndStoryView.vue';

import { useAuth } from '../model/authStore';
import { useCommunicsStore } from '@/model/communicsStore';

const routes = [
	{ name: "home", path: '/', component: HomeView ,  meta: { requiresAuth: true }},
	{ name: "login-callback", path: '/callback', component: AuthCallback},
	{ name: "silent-renew", path: '/silent-renew.html', component: SilentRenew},
	{ name: "edit", path: '/editStory', component: StoryEditorView, props:true,
		meta: { requiresAuth: true }},// only authenticated users can create posts
	{ name: "manager", path: '/manager', component: ManagerView, props: true, 
        meta: { requiresAuth: true }},// only authenticated users can create posts
	{ name: "directEdit", path: "/directEditStory/:userID/:storyID", component: DirectStoryEditorViewVue, },
	{ name: "newFromTemplate", path: "/useTemplate/:templateID/:userTag/", component: DirectStoryEditorViewVue, },
	{ name: "endPage", path: "/endPage", component:EndStoryView},
	{ name: "errorPage", path: "/errorPage", component:ErrorPageView}

	
]
export const router = createRouter({
	// 4. Provide the history implementation to use. We are using the hash history for simplicity here.
	history: createWebHistory(),
	routes, // short for `routes: routes`
});
router.beforeEach(async (to, from) =>{
	try {
		// if (to.meta.requiresAuth && !useAuth().isLoggedIn) {
		// 	return {path: "/", query: {redirect: to.fullPath}}
		// }
		if (to.name === "errorPage") {return true;}
		const communicsStore = useCommunicsStore();
		if (to.name === "directEdit" ) {
			console.log(`going to direct Edit story`);
			const email = to.params.email as string ?? "i3group.fbk@gmail.com";
			const user =  await useAuth().setupSharedUsr(email);
		
			if (user !== undefined) {
				await communicsStore.fetchAStory(user, to.params.storyID as string);
			}
			console.error(`no user can be authenticated/authorized ${to.params.userID}`);

		}
		else if (to.name === "newFromTemplate" ) {
			const userTag = to.params.usrtag as string ?? "anon_usr";
			const user =  await useAuth().setupSharedUsr("i3group.fbk@gmail.com");
			console.log(`edit from template with tag ${userTag}, user ${user.displayName}, templID ${to.params.templateID}`);
			await communicsStore.createFromTemplateAsync(user, 
				to.params.templateID as string, 
				to.params.userTag as string);
		}
		else if (to.name === "endPage") {
			
			//window.location.href = "https://www.google.com/search?q=test";
		}
	}
	catch (err) {
		console.error(err);
		return {name: 'errorPage'};
	}
	return true;



});

